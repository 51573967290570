<template>
  <div class="content-body" style="padding-top: 6rem">
    <div class="container-fluid">
      <div class="row">
        <div class="col-12">
          <div class="card">
            <div class="card-header">
              <h4 class="card-title">Form Add Events</h4>
            </div>
            <div class="card-body">
              <div class="basic-form">
                <!-- Section 1 -->
                <div class="card-header2 mt-5 mb-3">
                  <h4 class="card-title">Section 1</h4>
                </div>
                <div class="row mb-4">
                  <div class="col-md-6">
                    <div class="form-group">
                      <label>Universitas</label>
                      <input
                        v-model="post.universitas"
                        @input="generateSlug"
                        type="text"
                        class="form-control"
                        placeholder="Universitas"
                      />
                    </div>
                    <div class="form-group">
                      <label>Slug</label>
                      <input
                        v-model="post.slug"
                        type="text"
                        class="form-control"
                        placeholder="Slug URL"
                        readonly
                      />
                    </div>
                    <div class="form-group">
                      <label>Hastag</label>
                      <input
                        v-model="post.hastag"
                        type="text"
                        class="form-control"
                        placeholder="Hastag"
                      />
                    </div>
                    <div class="form-group">
                      <label>Title Satu</label>
                      <input
                        v-model="post.title_satu"
                        type="text"
                        class="form-control"
                        placeholder="Title Satu"
                      />
                    </div>
                    <div class="form-group">
                      <label>Description Satu</label>
                      <textarea
                        ref="summernoteSatu"
                        class="form-control summernote"
                        v-model="post.desc_satu"
                        rows="3"
                        placeholder="Description Satu"
                      ></textarea>
                    </div>
                    <div class="form-group">
                      <label>URL Button</label>
                      <input
                        v-model="post.url_button"
                        type="text"
                        class="form-control"
                        placeholder="URL Button"
                      />
                    </div>
                  </div>
                  <div class="col-md-6">
                    <div class="form-group">
                      <label>Image Hero Satu</label>
                      <input
                        @change="handleFileUpload('image_hero_satu', $event)"
                        type="file"
                        class="form-control"
                      />
                    </div>
                  </div>
                </div>

                <!-- Section 2 -->
                <div class="card-header2 mt-5 mb-3">
                  <h4 class="card-title">Section 2</h4>
                </div>
                <div class="row mb-4">
                  <div class="col-md-6">
                    <div class="form-group">
                      <label>Image Hero Dua</label>
                      <input
                        @change="handleFileUpload('image_hero_dua', $event)"
                        type="file"
                        class="form-control"
                      />
                    </div>
                  </div>
                  <div class="col-md-6">
                    <div class="form-group">
                      <label>Title Dua</label>
                      <input
                        v-model="post.title_dua"
                        type="text"
                        class="form-control"
                        placeholder="Title Dua"
                      />
                    </div>
                    <div class="form-group">
                      <label>Description Dua</label>
                      <textarea
                        ref="summernoteDua"
                        class="form-control summernote"
                        v-model="post.desc_dua"
                        rows="3"
                        placeholder="Description Dua"
                      ></textarea>
                    </div>
                  </div>
                </div>

                <!-- Section 3 -->
                <div class="card-header2 mt-5 mb-3">
                  <h4 class="card-title">Section 3</h4>
                </div>
                <div class="row mb-4">
                  <div class="col-md-6">
                    <div class="form-group">
                      <label>Title Tiga</label>
                      <input
                        v-model="post.title_tiga"
                        type="text"
                        class="form-control"
                        placeholder="Title Tiga"
                      />
                    </div>
                    <div class="form-group">
                      <label>Description Tiga</label>
                      <textarea
                        ref="summernoteTiga"
                        class="form-control summernote"
                        v-model="post.desc_tiga"
                        rows="3"
                        placeholder="Description Tiga"
                      ></textarea>
                    </div>
                  </div>
                  <div class="col-md-6">
                    <div class="form-group">
                      <label>Image Hero Tiga</label>
                      <input
                        @change="handleFileUpload('image_hero_tiga', $event)"
                        type="file"
                        class="form-control"
                      />
                    </div>
                  </div>
                </div>

                <!-- Section 4 -->
                <div class="row mb-4">
                  <div class="col-md-6">
                    <div class="form-group">
                      <label>URL Daftar</label>
                      <input
                        v-model="post.url_daftar"
                        type="text"
                        class="form-control"
                        placeholder="URL Daftar"
                      />
                    </div>
                  </div>
                </div>

                <!-- Meta SEO -->
                <div class="card-header2 mt-5 mb-3">
                  <h4 class="card-title">Meta SEO</h4>
                </div>
                <div class="form-group row">
                  <label class="col-sm-3 col-form-label">Meta Title</label>
                  <div class="col-sm-9">
                    <input
                      v-model="post.meta_title"
                      type="text"
                      class="form-control"
                      placeholder="Meta Title"
                    />
                  </div>
                </div>
                <div class="form-group row">
                  <label class="col-sm-3 col-form-label"
                    >Meta Description</label
                  >
                  <div class="col-sm-9">
                    <input
                      v-model="post.meta_desc"
                      type="text"
                      class="form-control"
                      placeholder="Meta Description"
                    />
                  </div>
                </div>
                <div class="form-group row">
                  <label class="col-sm-3 col-form-label">Meta Keywords</label>
                  <div class="col-sm-9">
                    <input
                      v-model="post.meta_keyword"
                      type="text"
                      class="form-control"
                      placeholder="Meta Keywords"
                    />
                  </div>
                </div>
                <div class="form-group row">
                  <label class="col-sm-3 col-form-label">Status</label>
                  <div class="col-sm-9">
                    <select v-model="post.is_publish" class="form-control">
                      <option value="true">Publish</option>
                      <option value="false">Unpublish</option>
                    </select>
                  </div>
                </div>

                <!-- Buttons -->
                <button type="button" @click="goBack" class="btn btn-warning btn-sm mr-1">
                  Kembali
                </button>
                <button type="button" @click="submit" class="btn btn-primary btn-sm mr-1">
                  Simpan
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { computed, onMounted, ref, watch, nextTick } from "vue";
import { useRoute, useRouter } from "vue-router";
import { useStore } from "vuex";
import Swal from "sweetalert2";
import axiosInstance from "../../axios";

export default {
  name: "Tambah Event BS",
  setup() {
    const store = useStore();
    const route = useRoute();
    const router = useRouter();
    const post = ref({
      universitas: "",
      slug: "",
      hastag: "",
      title_satu: "",
      desc_satu: "",
      image_hero_satu: null,
      url_button: "",
      title_dua: "",
      desc_dua: "",
      image_hero_dua: null,
      title_tiga: "",
      desc_tiga: "",
      image_hero_tiga: null,
      url_daftar: "",
      meta_title: "",
      meta_desc: "",
      meta_keyword: "",
      is_publish: "true",
      user: null,
    });

    const fetchUser = async () => {
      try {
        const response = await axiosInstance.get("/user", {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token.value}`,
          },
        });
        if (response.status === 200) {
          post.value.user = response.data.id;
        } else {
          Swal.fire({
            title: "Error",
            text: error.message,
            icon: "error",
            confirmButtonText: "OK",
          }).then(() => {
            store.dispatch("setAuth", false);
            store.dispatch("setToken", { token: null, expiry: null });
            localStorage.clear();
            window.location.href = "/";
          });
          //throw new Error('Failed to fetch user data');
        }
      } catch (error) {
        Swal.fire({
          title: "Error",
          text: error.message,
          icon: "error",
          confirmButtonText: "OK",
        }).then(() => {
          store.dispatch("setAuth", false);
          store.dispatch("setToken", { token: null, expiry: null });
          localStorage.clear();
          window.location.href = "/";
        });
      }
    };

    const summernoteSatu = ref(null);
    const summernoteDua = ref(null);
    const summernoteTiga = ref(null);

    // const token = ref("");
    const token = computed(() => store.state.token);

    const generateSlug = () => {
      post.value.slug = post.value.universitas
        .toLowerCase()
        .replace(/[^a-z0-9]+/g, "-")
        .replace(/^-+|-+$/g, "");
    };

    const handleFileUpload = (field, event) => {
      post.value[field] = event.target.files[0];
    };

    const goBack = () => {
      router.push({ name: "Event BS" });
    };

    const appendFormData = (formData, field, value) => {
      if (value) formData.append(field, value);
    };

    const submit = async () => {
      post.value.desc_satu = $(summernoteSatu.value).summernote("code");
      post.value.desc_dua = $(summernoteDua.value).summernote("code");
      post.value.desc_tiga = $(summernoteTiga.value).summernote("code");

      const formData = new FormData();

      // Append fields to FormData
      appendFormData(formData, "universitas", post.value.universitas);
      appendFormData(formData, "slug", post.value.slug);
      appendFormData(formData, "hastag", post.value.hastag);
      appendFormData(formData, "title_satu", post.value.title_satu);
      appendFormData(formData, "desc_satu", post.value.desc_satu);
      appendFormData(formData, "image_hero_satu", post.value.image_hero_satu);
      appendFormData(formData, "url_button", post.value.url_button);
      appendFormData(formData, "title_dua", post.value.title_dua);
      appendFormData(formData, "desc_dua", post.value.desc_dua);
      appendFormData(formData, "image_hero_dua", post.value.image_hero_dua);
      appendFormData(formData, "title_tiga", post.value.title_tiga);
      appendFormData(formData, "desc_tiga", post.value.desc_tiga);
      appendFormData(formData, "image_hero_tiga", post.value.image_hero_tiga);
      appendFormData(formData, "url_daftar", post.value.url_daftar);
      appendFormData(formData, "meta_title", post.value.meta_title);
      appendFormData(formData, "meta_desc", post.value.meta_desc);
      appendFormData(formData, "meta_keyword", post.value.meta_keyword);
      appendFormData(formData, "user", post.value.user);
      appendFormData(formData, "is_publish", post.value.is_publish);

      try {
        const response = await axiosInstance.post("/events-bs", formData, {
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: `Bearer ${token.value}`,
          },
        });

        if (response.status === 200) {
          Swal.fire({
            title: "Success",
            text: "Data created successfully!",
            icon: "success",
            confirmButtonText: "OK",
          }).then(() => {
            goBack();
          });
        } else {
          Swal.fire({
            title: "Error",
            text: "Error creating data",
            icon: "error",
            confirmButtonText: "OK",
          });
        }
      } catch (error) {
        Swal.fire({
          title: "Error",
          text: `Error: ${error.message}`,
          icon: "error",
          confirmButtonText: "OK",
        });
      }
    };

    onMounted(async () => {
      await fetchUser();
      await nextTick();
      $(summernoteSatu.value).summernote({
        height: 200,
        callbacks: {
          onChange: (contents) => {
            post.value.desc_satu = contents;
          },
        },
      });

      $(summernoteDua.value).summernote({
        height: 200,
        callbacks: {
          onChange: (contents) => {
            post.value.desc_dua = contents;
          },
        },
      });

      $(summernoteTiga.value).summernote({
        height: 200,
        callbacks: {
          onChange: (contents) => {
            post.value.desc_tiga = contents;
          },
        },
      });
    });

    return {
      post,
      handleFileUpload,
      generateSlug,
      submit,
      goBack,
      summernoteSatu,
      summernoteDua,
      summernoteTiga,
    };
  },
};
</script>
