<template>
  <div class="content-body" style="padding-top: 6rem">
    <div class="container-fluid">
      <div class="page-titles">
        <ol class="breadcrumb">
          <li class="breadcrumb-item"><a href="javascript:void(0)">Info</a></li>
          <li class="breadcrumb-item">
            <a href="javascript:void(0)">{{ currentRouteName }}</a>
          </li>
        </ol>
      </div>

      <div class="row">
        <div class="col-12">
          <div class="card">
            <div class="card-header">
              <h4 class="card-title">Edit User Level</h4>
            </div>
            <div class="card-body">
              <form @submit.prevent="updateUser">
                <div class="form-group">
                  <label for="name">User Level</label>
                  <input
                    type="text"
                    v-model="post.name"
                    id="name"
                    class="form-control"
                    placeholder="Enter user level"
                  />
                </div>
                <button
                  type="button"
                  @click="goBack"
                  class="btn btn-warning btn-sm mr-1 "
                >
                  Kembali
                </button>
                <button
                  type="button"
                  @click="updateData"
                  class="btn btn-primary btn-sm mr-1"
                >
                  Update
                </button>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { computed, onMounted, ref } from "vue";
import { useRoute, useRouter } from "vue-router";
import { useStore } from "vuex";
import Swal from "sweetalert2";
import axiosInstance from "../../axios";

export default {
  name: "EditUserLevel",
  setup() {
    const store = useStore();
    const route = useRoute();
    const router = useRouter();

    const post = ref({
      id: null,
      name: "",
    });

    const token = computed(() => store.state.token);
    const currentRouteName = computed(() => route.name);

    // Fetch user session
    const fetchUser = async () => {
      try {
        const response = await axiosInstance.get("/user", {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token.value}`,
          },
        });
        if (response.status === 200) {
          post.value.user = response.data.id;
        } else {
          Swal.fire({
            title: "Error",
            text: "Failed to validate user session",
            icon: "error",
            confirmButtonText: "OK",
          }).then(() => {
            store.dispatch("setAuth", false);
            store.dispatch("setToken", { token: null, expiry: null });
            localStorage.clear();
            router.push({ name: "Login" });
          });
        }
      } catch (error) {
        Swal.fire({
          title: "Error",
          text: "Session expired or invalid. Please log in again.",
          icon: "error",
          confirmButtonText: "OK",
        }).then(() => {
          store.dispatch("setAuth", false);
          store.dispatch("setToken", { token: null, expiry: null });
          localStorage.clear();
          router.push({ name: "Login" });
        });
      }
    };

    // Fetch user level data
    const fetchData = async () => {
      const id = route.params.id;
      try {
        const response = await axiosInstance.get(`/user-levels/${id}`, {
          headers: {
            Authorization: `Bearer ${token.value}`,
          },
        });

        if (response.status === 200) {
          post.value = {
            ...post.value,
            name: response.data.data.name,
          };
        } else {
          Swal.fire({
            title: "Error",
            text: `Error: ${response.data.data.message}`,
            icon: "error",
            confirmButtonText: "OK",
          });
        }
      } catch (error) {
        Swal.fire({
          title: "Error",
          text: `Error: ${error.message}`,
          icon: "error",
          confirmButtonText: "OK",
        });
      }
    };

    const updateData = async () => {
      try {
        const response = await axiosInstance.put(
          `/user-levels/${route.params.id}`, // Use post.value.id
          post.value, // Send updated data as JSON
          {
            headers: {
              Authorization: `Bearer ${token.value}`,
              "Content-Type": "application/json",
            },
          }
        );

        if (response.status === 200) {
          Swal.fire({
            title: "Success",
            text: "User level updated successfully!",
            icon: "success",
            confirmButtonText: "OK",
          }).then(() => {
            router.push({ name: "User Level" });
          });
        } else {
          Swal.fire({
            title: "Error",
            text: `Error: ${response.data.message}`,
            icon: "error",
            confirmButtonText: "OK",
          });
        }
      } catch (error) {
        Swal.fire({
          title: "Error",
          text: `Error: ${error.message}`,
          icon: "error",
          confirmButtonText: "OK",
        });
      }
    };

    const goBack = () => {
      router.push({ name: "User Level" });
    };

    onMounted(async () => {
      await fetchUser(); // Validate user session on component mount
      await fetchData(); // Fetch user level data after session validation
    });

    return {
      post,
      goBack,
      fetchUser,
      fetchData,
      updateData,
      currentRouteName,
    };
  },
};
</script>

<style scoped>
/* Add some basic styling */
</style>
