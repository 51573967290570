<template>
  <div class="content-body" style="padding-top: 6rem">
    <div class="container-fluid">
      <div class="row">
        <div class="col-12">
          <div class="card">
            <div class="card-header">
              <h4 class="card-title">Tambah User Level</h4>
            </div>
            <div class="card-body">
              <form @submit.prevent="submitForm">
                <div class="form-group">
                  <label for="name">Tambah User Level</label>
                  <input
                    type="text"
                    v-model="post.name"
                    id="name"
                    class="form-control"
                    placeholder="Enter user level"
                    required
                  />
                </div>
                <button
                  type="button"
                  @click="goBack"
                  class="btn btn-warning btn-sm mr-1"
                >
                  Kembali
                </button>
                <button type="submit" class="btn btn-primary btn-sm mr-1">Simpan</button>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { computed, onMounted, ref } from "vue";
import { useRoute, useRouter } from "vue-router";
import { useStore } from "vuex";
import Swal from "sweetalert2";
import axiosInstance from "../../axios";

export default {
  name: "Tambah Level",
  setup() {
    const store = useStore();
    const router = useRouter();
    const post = ref({
      name: "",
    });

    const token = computed(() => store.getters.token);

    const fetchUser = async () => {
      try {
        const response = await axiosInstance.get("/user", {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token.value}`,
          },
        });
        if (response.status !== 200) {
          throw new Error("User not found");
        }
      } catch (error) {
        Swal.fire({
          title: "Error",
          text: error.message,
          icon: "error",
          confirmButtonText: "OK",
        }).then(() => {
          store.dispatch("setAuth", false);
          store.dispatch("setToken", { token: null, expiry: null });
          localStorage.clear();
          router.replace({ name: "Login" });
        });
      }
    };

    const goBack = () => {
      router.push({ name: "User Level" });
    };

    const submitForm = async () => {
      try {
        const response = await axiosInstance.post(
          "/user-levels",
          { name: post.value.name },
          {
            headers: {
              Authorization: `Bearer ${token.value}`,
              "Content-Type": "application/json",
            },
          }
        );

        if (response.status === 200) {
          Swal.fire({
            title: "Success",
            text: "User Level created successfully!",
            icon: "success",
            confirmButtonText: "OK",
          }).then(() => {
            goBack();
          });
        } else {
          Swal.fire({
            title: "Error",
            text: "Unexpected response from server.",
            icon: "error",
            confirmButtonText: "OK",
          });
        }
      } catch (error) {
        Swal.fire({
          title: "Error",
          text: `Error: ${error.message}`,
          icon: "error",
          confirmButtonText: "OK",
        });
      }
    };

    onMounted(async () => {
      await fetchUser();
    });

    return {
      post,
      submitForm,
      goBack,
    };
  },
};
</script>

<style scoped>
/* Add some basic styling */
</style>
