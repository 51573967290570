<template>
  <div class="modal-overlay" v-if="showModal">
    <div class="modal-content">
      <span class="close-btn" @click="closeModal">&times;</span>

      <h2>Detail Kotak Masuk</h2>
      <td>
        {{
          selectedItem && selectedItem.created_date
            ? formatDate(selectedItem.created_date)
            : "-"
        }}
      </td>

      <table class="detail-table">
        <tr>
          <th>Nama</th>
          <td>{{ selectedItem?.nama || "-" }}</td>
          <th>Company</th>
          <td>{{ selectedItem?.company || "-" }}</td>
        </tr>
        <tr>
          <th>Email</th>
          <td>{{ selectedItem?.email || "-" }}</td>
          <th>Phone</th>
          <td>{{ selectedItem?.no_tlp || "-" }}</td>
        </tr>
        <tr>
          <th>Subject</th>
          <td colspan="3">{{ selectedItem?.jenis || "-" }}</td>
        </tr>
        <tr>
          <th>Message</th>
          <td colspan="3">{{ selectedItem?.deskripsi || "-" }}</td>
        </tr>
      </table>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    showModal: Boolean,
    selectedItem: Object,
  },
  methods: {
    closeModal() {
      this.$emit("close");
    },
    formatDate(value) {
      if (value) {
        const date = new Date(value);

        const days = [
          "Minggu",
          "Senin",
          "Selasa",
          "Rabu",
          "Kamis",
          "Jumat",
          "Sabtu",
        ];
        const dayName = days[date.getDay()];

        const day = String(date.getDate()).padStart(2, "0");
        const month = String(date.getMonth() + 1).padStart(2, "0");
        const year = date.getFullYear();

        return `${dayName}, ${day}-${month}-${year}`;
      }
      return "";
    },
  },
};
</script>

<style>
.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}
.modal-content {
  background: #fff;
  padding: 20px;
  border-radius: 8px;
  width: 80%;
  max-width: 800px;
  position: relative;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
}
.close-btn {
  position: absolute;
  top: 10px;
  right: 15px;
  font-size: 20px;
  cursor: pointer;
}
.detail-table {
  width: 100%;
  border-collapse: collapse;
  margin-top: 10px;
}
.detail-table th,
.detail-table td {
  padding: 10px;
  border: 1px solid #ddd;
  text-align: left;
  vertical-align: top;
}
.detail-table th {
  background-color: #f4f4f4;
  width: 25%;
}
.detail-table td {
  width: 25%;
}
</style>
