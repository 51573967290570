<template>
  <div class="content-body" style="padding-top: 6rem">
    <div class="container-fluid">
      <div class="row">
        <div class="col-12">
          <div class="card">
            <div class="card-header">
              <h4 class="card-title">Edit User</h4>
            </div>
            <div class="card-body">
              <div class="basic-form">
                <form @submit.prevent="submitForm">
                  <div class="form-group row">
                    <label class="col-sm-3 col-form-label">Nama</label>
                    <div class="col-sm-9">
                      <input
                        type="text"
                        v-model="post.name"
                        class="form-control"
                        id="name"
                        required
                      />
                    </div>
                  </div>
                  <div class="form-group row">
                    <label class="col-sm-3 col-form-label">Email</label>
                    <div class="col-sm-9">
                      <input
                        type="email"
                        v-model="post.email"
                        class="form-control"
                        id="email"
                        required
                      />
                    </div>
                  </div>
                  <div class="form-group row">
                    <label class="col-sm-3 col-form-label">Password</label>
                    <div class="col-sm-9">
                      <input
                        type="password"
                        v-model="post.password"
                        class="form-control"
                        id="password"
                        placeholder="Leave blank if not changing"
                      />
                    </div>
                  </div>
                  <div class="form-group row">
                    <label class="col-sm-3 col-form-label">User Level</label>
                    <div class="col-sm-9">
                      <select
                        v-model="post.users_level"
                        class="form-control"
                        required
                      >
                        <option disabled value="">Pilih Level Pengguna</option>
                        <option
                          v-for="level in userLevels"
                          :key="level.id"
                          :value="level.id"
                        >
                          {{ level.name }}
                        </option>
                      </select>
                    </div>
                  </div>
                  <div class="form-group row">
                    <label class="col-sm-3 col-form-label">Status</label>
                    <div class="col-sm-9">
                      <select v-model="post.is_active" class="form-control">
                        <option value="1">Publish</option>
                        <option value="0">Unpublish</option>
                      </select>
                    </div>
                  </div>
                  <button
                    type="button"
                    @click="goBack"
                    class="btn btn-warning mr-3 btn-sm mr-1"
                  >
                    Kembali
                  </button>
                  <button type="submit" class="btn btn-primary btn-sm mr-1">Simpan</button>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { computed, onMounted, ref } from "vue";
import { useRoute, useRouter } from "vue-router";
import { useStore } from "vuex";
import Swal from "sweetalert2";
import axiosInstance from "../../axios";

export default {
  name: "Edit User",
  setup() {
    const store = useStore();
    const router = useRouter();
    const route = useRoute();
    const post = ref({
      name: "",
      email: "",
      password: "",
      users_level: null,
      is_active: "",
    });

    const userLevels = ref([]); // Array for user levels
    const token = computed(() => store.getters.token);

    const fetchUser = async () => {
      try {
        const response = await axiosInstance.get("/user", {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token.value}`,
          },
        });
        if (response.status === 200) {
          post.value.user = response.data.id;
        } else {
          Swal.fire({
            title: "Error",
            text: "Failed to validate user session",
            icon: "error",
            confirmButtonText: "OK",
          }).then(() => {
            store.dispatch("setAuth", false);
            store.dispatch("setToken", { token: null, expiry: null });
            localStorage.clear();
            router.push({ name: "Login" });
          });
        }
      } catch (error) {
        Swal.fire({
          title: "Error",
          text: "Session expired or invalid. Please log in again.",
          icon: "error",
          confirmButtonText: "OK",
        }).then(() => {
          store.dispatch("setAuth", false);
          store.dispatch("setToken", { token: null, expiry: null });
          localStorage.clear();
          router.push({ name: "Login" });
        });
      }
    };

    const fetchData = async () => {
      const id = route.params.id;
      try {
        const response = await axiosInstance.get(`/users/${id}`, {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token.value}`,
          },
        });
        if (response.status === 200) {
          console.log(response);
          post.value = {
            ...post.value,
            name: response.data.data.name,
            email: response.data.data.email,
            password: response.data.data.password,
            users_level: response.data.data.users_level,
            is_active: response.data.data.is_active,
          };
        } else {
          throw new Error("User not found");
        }
      } catch (error) {
        Swal.fire({
          title: "Error",
          text: error.message,
          icon: "error",
          confirmButtonText: "OK",
        });
      }
    };

    const fetchUserLevels = async () => {
      try {
        const response = await axiosInstance.get("/user-levels", {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token.value}`,
          },
        });
        if (response.status === 200) {
          userLevels.value = response.data.data; // Assuming response.data contains the levels
        } else {
          throw new Error("Unable to fetch user levels");
        }
      } catch (error) {
        Swal.fire({
          title: "Error",
          text: error.message,
          icon: "error",
          confirmButtonText: "OK",
        });
      }
    };

    const goBack = () => {
      router.push({ name: "User List" });
    };

    const submitForm = async () => {
      const userId = route.params.id;
      const data = {
        name: post.value.name,
        email: post.value.email,
        users_level: post.value.users_level,
        is_active: post.value.is_active,
      };

      if (post.value.password) {
        data.password = post.value.password; // Include password only if it's being updated
      }

      try {
        const response = await axiosInstance.post(
          `/users-update/${userId}`,
          data,
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${token.value}`,
            },
          }
        );

        if (response.status === 200) {
          Swal.fire({
            title: "Success",
            text: "User updated successfully!",
            icon: "success",
            confirmButtonText: "OK",
          }).then(() => {
            goBack();
          });
        } else {
          Swal.fire({
            title: "Error",
            text: "Unexpected response from server.",
            icon: "error",
            confirmButtonText: "OK",
          });
        }
      } catch (error) {
        Swal.fire({
          title: "Error",
          text: `Error: ${error.message}`,
          icon: "error",
          confirmButtonText: "OK",
        });
      }
    };

    onMounted(async () => {
      await fetchUser();
      await fetchData(); // Fetch user data on mount
      await fetchUserLevels(); // Fetch user levels on mount
    });

    return {
      post,
      userLevels,
      submitForm,
      goBack,
    };
  },
};
</script>

<style scoped>
/* Add some basic styling */
</style>
