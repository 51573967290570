<template>
  <div class="deznav" v-if="isAuthenticated">
    <div class="deznav-scroll">
      <!-- <a
        href="javascript:void(0)"
        class="add-menu-sidebar"
        data-toggle="modal"
        data-target="#addOrderModalside"
        >Member Card</a
      > -->
      <ul class="metismenu" id="menu">
        <!-- <li
          v-if="v === '8' || v === '5' || v === '1' || v === '11'"
          class="mm-active"
        >
          <a
            class="ai-icon"
            href="javascript:void()"
            aria-expanded="true"
          >
            <i class="flaticon-381-speedometer"></i>
            <span class="nav-text">Dashboard</span>
          </a> -->

        <li v-if="v === '8' || v === '5' || v === '1' || v === '11'">
          <router-link
            :to="{ name: 'Dashboard' }"
            class="ai-icon"
            aria-expanded="false"
          >
            <i class="flaticon-381-speedometer"></i>
            <span class="nav-text">Dashboard</span>
          </router-link>
        </li>
        <!-- <ul aria-expanded="false">
            <li>
              <router-link :to="{ name: 'Dashboard' }">Dashboard</router-link>
            </li>
            <li>
              <router-link :to="{ name: 'Sertifikat' }">Sertifikat</router-link>
            </li>
            <li>
              <router-link :to="{ name: 'Profile' }">Profile</router-link>
            </li>
          </ul> -->
        <!-- </li> -->

        <li v-if="v === '8' || v === '5' || v === '1'">
          <a
            class="has-arrow ai-icon"
            href="javascript:void()"
            aria-expanded="false"
          >
            <i class="flaticon-381-help-1"></i>
            <span class="nav-text">Info</span>
          </a>
          <ul aria-expanded="false">
            <li>
              <router-link :to="{ name: 'Hubungi Kami' }"
                >Hubungi Kami</router-link
              >
            </li>
            <li>
              <router-link :to="{ name: 'Tentang Kami' }"
                >Tentang Kami</router-link
              >
            </li>
            <li>
              <router-link :to="{ name: 'Contact Form' }"
                >Contact Form</router-link
              >
            </li>
            <li>
              <router-link :to="{ name: 'Journey' }">Journey</router-link>
            </li>
            <li>
              <router-link :to="{ name: 'Bussines' }">Retail/B2B</router-link>
            </li>
            <li>
              <router-link :to="{ name: 'Kategori' }">Kategori</router-link>
            </li>
            <li>
              <router-link :to="{ name: 'Sub Kategori' }"
                >Sub Kategori</router-link
              >
            </li>
            <!-- <li><a href="./post-details.html">Tanya Jawab</a></li> -->
          </ul>
        </li>

        <li v-if="v === '8' || v === '5' || v === '1'">
          <a
            class="has-arrow ai-icon"
            href="javascript:void()"
            aria-expanded="false"
          >
            <i class="flaticon-381-album"></i>
            <span class="nav-text">Slider</span>
          </a>
          <ul aria-expanded="false">
            <li>
              <router-link :to="{ name: 'Slider Hero' }"
                >Slider Hero</router-link
              >
            </li>
            <li>
              <router-link :to="{ name: 'Slider Product Home' }"
                >Slider Product Home</router-link
              >
            </li>
            <!-- <li>
              <router-link :to="{ name: 'Slider Award Home' }"
                >Slider Award Home</router-link
              >
            </li>
            <li>
              <router-link :to="{ name: 'Testimoni' }"
                >Apa Kata Mereka</router-link
              >
            </li> -->
          </ul>
        </li>

        <li v-if="v === '8' || v === '5' || v === '1'">
          <a
            class="has-arrow ai-icon"
            href="javascript:void()"
            aria-expanded="false"
          >
            <i class="flaticon-381-user-8"></i>
            <span class="nav-text">Header</span>
          </a>
          <ul aria-expanded="false">
            <router-link :to="{ name: 'Section Head' }"
              >Data Section Page Header</router-link
            >
          </ul>
        </li>

        <li v-if="v === '8' || v === '5' || v === '1'">
          <a
            class="has-arrow ai-icon"
            href="javascript:void()"
            aria-expanded="false"
          >
            <i class="flaticon-381-album-2"></i>
            <span class="nav-text">Post</span>
          </a>
          <ul aria-expanded="false">
            <li>
              <router-link :to="{ name: 'Award' }">Post Award</router-link>
            </li>
            <li>
              <router-link :to="{ name: 'Testimonial' }"
                >Post Testimoni</router-link
              >
            </li>
            <li>
              <router-link :to="{ name: 'Event BS' }"
                >Post Event BS</router-link
              >
            </li>
            <!-- <li>
              <router-link :to="{ name: 'Artikel Event' }"
                >Post Artikel Event</router-link
              >
            </li> -->
            <li>
              <router-link :to="{ name: 'Product' }">Post Product</router-link>
            </li>
            <li>
              <router-link :to="{ name: 'Event Recap' }"
                >Post Event Recap</router-link
              >
            </li>
            <li>
              <router-link :to="{ name: 'Blog' }">Post Blog</router-link>
            </li>
            <li>
              <router-link :to="{ name: 'Teknologi' }"
                >Post Teknologi</router-link
              >
            </li>
            <li>
              <router-link :to="{ name: 'Knowledge' }"
                >Post Knowledge</router-link
              >
            </li>
          </ul>
        </li>
        <!-- <li>
          <a
            class="has-arrow ai-icon"
            href="javascript:void()"
            aria-expanded="false"
          >
            <i class="flaticon-381-album-2"></i>
            <span class="nav-text">Content Events</span>
          </a>
          <ul aria-expanded="false">
            <li><a href="#">list Events</a></li>
            <li><a href="#">Add Events</a></li>
          </ul>
        </li> -->
        <!-- <li>
          <a
            class="has-arrow ai-icon"
            href="javascript:void()"
            aria-expanded="false"
          >
            <i class="flaticon-381-percentage-1"></i>
            <span class="nav-text">Banner Promo</span>
          </a>
          <ul aria-expanded="false">
            <li><a href="./ui-accordion.html">Promo</a></li>
          </ul>
        </li> -->

        <li v-if="v === '5' || v === '1' || v === '11'">
          <router-link
            class="has-arrow ai-icon"
            :to="{ name: 'Career' }"
            aria-expanded="false"
          >
            <i class="flaticon-381-view-2"></i>
            <span class="nav-text">Career</span>
          </router-link>
          <ul aria-expanded="false">
            <li>
              <router-link :to="{ name: 'Pelamar' }">Pelamar</router-link>
            </li>
            <li>
              <router-link :to="{ name: 'Jobs' }">Jobs</router-link>
            </li>
            <li>
              <router-link :to="{ name: 'Division' }">Division</router-link>
            </li>
          </ul>
        </li>

        <li v-if="v === '8' || v === '5' || v === '1'">
          <router-link
            :to="{ name: 'File Manager' }"
            class="ai-icon"
            aria-expanded="false"
          >
            <i class="flaticon-381-file-2"></i>
            <span class="nav-text">File Manager</span>
          </router-link>
        </li>

        <li v-if="v === '5' || v === '1'">
          <a
            class="has-arrow ai-icon"
            href="javascript:void()"
            aria-expanded="false"
          >
            <i class="flaticon-381-folder-1"></i>
            <span class="nav-text">API LOGGER</span>
          </a>
          <ul aria-expanded="false">
            <router-link :to="{ name: 'API' }">API</router-link>
          </ul>
          <ul aria-expanded="false">
            <router-link :to="{ name: 'Logger' }">Data Logger</router-link>
          </ul>
        </li>

        <li v-if="v === '5' || v === '1'">
          <a
            class="has-arrow ai-icon"
            href="javascript:void()"
            aria-expanded="false"
          >
            <i class="flaticon-381-key"></i>
            <span class="nav-text">Admin</span>
          </a>
          <ul aria-expanded="false">
            <router-link :to="{ name: 'User Level' }">User Level</router-link>
            <router-link :to="{ name: 'User List' }">Data User</router-link>
          </ul>
        </li>
        <li>
          <a @click="logout" class="ai-icon" aria-expanded="false">
            <i class="flaticon-381-exit-1"></i>
            <span class="nav-text">Keluar</span>
          </a>
        </li>
      </ul>

      <div class="copyright">
        <p>© 2013 - 2023 PT. Bintang Sempurna ©Copyright</p>
      </div>
    </div>
  </div>
</template>

<script>
import { computed, getCurrentInstance } from "vue";
import { useStore } from "vuex";
import { useRouter } from "vue-router";
import Swal from "sweetalert2";
import axiosInstance from "../axios";
import router from "@/router";

export default {
  name: "LeftMenu",
  setup() {
    const store = useStore();
    const router = useRouter();
    const isAuthenticated = computed(() => store.getters.isAuthenticated);
    const token = computed(() => store.getters.token);
    const v = computed(() => store.getters.v);

    const logout = async () => {
      try {
        const response = await axiosInstance.post(
          "/logout",
          {}, // Pass an empty object for the body
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${token.value}`,
            },
          }
        );
        if (response.status === 200) {
          Swal.fire({
            title: "Logout successful",
            text: "You have been logged out.",
            icon: "success",
            confirmButtonText: "OK",
          }).then(() => {
            store.dispatch("setAuth", false);
            store.dispatch("setToken", {
              token: null,
              tokenExpiry: null,
              v: null,
            });
            localStorage.clear();
            router.replace({ name: "Login" });
          });
        } else {
          Swal.fire({
            title: "Success",
            text: `Logout successful!`,
            icon: "success",
            confirmButtonText: "OK",
          }).then(() => {
            store.dispatch("setAuth", false);
            store.dispatch("setToken", { token: null, expiry: null });
            localStorage.clear();
            router.replace({ name: "Login" });
          });
        }
      } catch (error) {
        Swal.fire({
          title: "Error during logout",
          text: error.message,
          icon: "error",
          confirmButtonText: "OK",
        });
      }
    };

    return {
      isAuthenticated,
      logout,
      v,
    };
  },
};
</script>

<style scoped>
.ai-icon {
  cursor: pointer;
}
</style>
