import { createStore } from "vuex";

export default createStore({
  state: {
    isAuthenticated: !!localStorage.getItem("token"),
    token: localStorage.getItem("token") || null,
    tokenExpiry: localStorage.getItem("tokenExpiry") || null,
    v: localStorage.getItem("v") || null, // Add v state
  },
  mutations: {
    setAuth(state, status) {
      state.isAuthenticated = status;
    },
    setToken(state, { token, expiry, v }) {
      state.token = token;
      state.tokenExpiry = expiry;
      state.v = v; // Set v
      if (token) {
        localStorage.setItem("token", token);
        localStorage.setItem("tokenExpiry", expiry);
        localStorage.setItem("v", v);
      } else {
        localStorage.removeItem("token");
        localStorage.removeItem("tokenExpiry");
        localStorage.removeItem("v");
      }
    },
  },
  actions: {
    setAuth({ commit }, status) {
      commit("setAuth", status);
    },
    setToken({ commit }, payload) {
      commit("setToken", payload);
    },
  },
  getters: {
    isAuthenticated: (state) => state.isAuthenticated,
    token: (state) => state.token,
    tokenExpiry: (state) => state.tokenExpiry,
    v: (state) => state.v,
  },
});
