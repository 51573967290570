<template>
  <div class="content-body" style="padding-top: 6rem">
    <div class="container-fluid">
      <div class="page-titles">
        <ol class="breadcrumb">
          <li class="breadcrumb-item">
            <a href="javascript:void(0)">{{ currentRouteName }}</a>
          </li>
        </ol>
      </div>

      <div class="row">
        <div class="col-12">
          <div class="card">
            <div class="card-header">
              <h4 class="card-title">Data {{ currentRouteName }}</h4>
              <div class="search-bar">
                <input
                  v-model="searchQuery"
                  type="text"
                  placeholder="Search..."
                  class="form-control"
                />
              </div>
            </div>
            <div class="card-body">
              <!-- <table class="table header-border table-responsive-sm"> -->
                <div class="table-responsive">
                  <table class="table">
                <thead>
                  <tr>
                    <th>#</th>
                    <th>Name Logger</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-if="filteredLogger.length === 0" class="text-center">
                    <td colspan="2">No Data</td>
                  </tr>
                  <tr
                    v-for="(logItem, index) in paginatedLogger"
                    :key="logItem"
                  >
                    <td>{{ index + 1 + (currentPage - 1) * itemsPerPage }}</td>
                    <td>
                      <router-link
                        :to="{ name: 'Detail Logger', params: { id: logItem } }"
                        >{{ logItem }}</router-link
                      >
                    </td>
                  </tr>
                </tbody>
              </table>
              </div>
              <!-- Pagination -->
              <nav>
                <ul class="pagination justify-content-end mt-3">
                  <li
                    class="page-item"
                    :class="{ disabled: currentPage === 1 }"
                  >
                    <a
                      class="page-link"
                      href="javascript:void(0)"
                      @click="prevPage"
                      >Previous</a
                    >
                  </li>
                  <li
                    v-for="page in totalPages"
                    :key="page"
                    class="page-item"
                    :class="{ active: page === currentPage }"
                  >
                    <a
                      class="page-link"
                      href="javascript:void(0)"
                      @click="goToPage(page)"
                    >
                      {{ page }}
                    </a>
                  </li>
                  <li
                    class="page-item"
                    :class="{ disabled: currentPage === totalPages }"
                  >
                    <a
                      class="page-link"
                      href="javascript:void(0)"
                      @click="nextPage"
                      >Next</a
                    >
                  </li>
                </ul>
              </nav>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { onMounted, ref, computed } from "vue";
import { useRoute, useRouter } from "vue-router";
import { useStore } from "vuex";
import Swal from "sweetalert2";
import axiosInstance from "../axios";

export default {
  name: "Logger",
  setup() {
    const router = useRouter();
    const route = useRoute();
    const store = useStore();
    const logger = ref([]);
    const currentRouteName = computed(() => route.name);
    const isAuthenticated = computed(() => store.getters.isAuthenticated);
    const token = computed(() => store.getters.token);
    const tokenExpiry = computed(() => store.getters.tokenExpiry);
    const searchQuery = ref("");
    const currentPage = ref(1);
    const itemsPerPage = ref(5);

    const fetchData = async () => {
      if (
        !token.value ||
        !tokenExpiry.value ||
        new Date() > new Date(tokenExpiry.value) ||
        !isAuthenticated.value
      ) {
        Swal.fire({
          title: "Error",
          text: `Session expired or invalid`,
          icon: "error",
          confirmButtonText: "OK",
        }).then(() => {
          store.dispatch("setAuth", false);
          store.dispatch("setToken", { token: null, expiry: null });
          localStorage.clear();
          window.location.href = "/";
        });
        return;
      }
      try {
        const response = await axiosInstance.get("/logger", {
          headers: {
            Authorization: `Bearer ${token.value}`,
          },
        });

        if (response.status === 200) {
          const data = await response.data;
          logger.value = data.log_files;
        } else {
          Swal.fire({
            title: "Error",
            text: `Error: ${data.message}`,
            icon: "error",
            confirmButtonText: "OK",
          });
        }
      } catch (error) {
        await Swal.fire({
          title: "Error",
          text: `Error: ${error.message}`,
          icon: "error",
          confirmButtonText: "OK",
        }).then(() => {
          store.dispatch("setAuth", false);
          store.dispatch("setToken", { token: null, expiry: null });
          localStorage.clear();
          router.push({ name: "Login" });
        });
      }
    };

    const filteredLogger = computed(() => {
      if (!searchQuery.value) {
        return logger.value;
      }
      return logger.value.filter((logItem) =>
        logItem.toLowerCase().includes(searchQuery.value.toLowerCase())
      );
    });

    const totalPages = computed(() => {
      return Math.ceil(filteredLogger.value.length / itemsPerPage.value);
    });

    const paginatedLogger = computed(() => {
      const start = (currentPage.value - 1) * itemsPerPage.value;
      const end = start + itemsPerPage.value;
      return filteredLogger.value.slice(start, end);
    });

    const goToPage = (page) => {
      if (page >= 1 && page <= totalPages.value) {
        currentPage.value = page;
      }
    };

    const nextPage = () => {
      if (currentPage.value < totalPages.value) {
        currentPage.value++;
      }
    };

    const prevPage = () => {
      if (currentPage.value > 1) {
        currentPage.value--;
      }
    };

    onMounted(fetchData);

    return {
      currentRouteName,
      fetchData,
      logger,
      searchQuery,
      currentPage,
      itemsPerPage,
      paginatedLogger,
      totalPages,
      filteredLogger,
      goToPage,
      nextPage,
      prevPage,
    };
  },
};
</script>

<style scoped>
.content-body {
 
  background: #f4f4f9;
}
</style>
