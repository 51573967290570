<template>
  <div class="content-body" style="padding-top: 6rem">
    <div class="container-fluid">
      <div class="page-titles">
        <ol class="breadcrumb">
          <li class="breadcrumb-item"><a href="javascript:void(0)">Info</a></li>
          <li class="breadcrumb-item">
            <a href="javascript:void(0)">{{ currentRouteName }}</a>
          </li>
        </ol>
      </div>

      <!-- Button Add -->
      <router-link
        :to="{ name: 'Tambah Artikel Event' }"
        class="btn btn-primary mb-4"
        >Tambah</router-link
      >
      <!-- Tutup -->

      <div class="row">
        <div class="col-12">
          <div class="card">
            <div class="card-header">
              <h4 class="card-title">Data {{ currentRouteName }}</h4>

              <!-- Search bar -->
              <div class="d-flex align-items-center">
                <input
                  v-model="searchQuery"
                  @input="handleSearch"
                  class="form-control mr-2"
                  placeholder="Cari berdasarkan judul..."
                  style="width: 250px"
                />
                <button @click="handleSearch" class="btn btn-primary">
                  <i class="fa fa-search"></i>
                </button>
              </div>
            </div>
            <div class="card-body">
              <table class="table header-border table-responsive-sm">
                <thead>
                  <tr>
                    <th>#</th>
                    <th>Judul</th>
                    <th>Kategori</th>
                    <th>Sub Kategori</th>
                    <th>Thumbnail</th>
                    <th>Deskripsi</th>
                    <th>Status</th>
                    <th>Action</th>
                  </tr>
                </thead>
                <tbody>
                  <tr
                    v-if="!paginatedData || !paginatedData.length"
                    class="text-center"
                  >
                    <td colspan="6">No Data</td>
                  </tr>
                  <tr v-for="(item, index) in paginatedData" :key="item.id">
                    <td>{{ index + 1 }}</td>
                    <td>{{ item.title }}</td>
                    <td>{{ item.kategori }}</td>
                    <td>
                      <span class="badge badge-primary mb-1">
                        {{ item.sub_kategori }}
                      </span>
                    </td>
                    <td>
                      <img
                        :src="`https://api.dev.bintangsempurna.co.id/images/blogs/${item.image}`"
                        alt="Preview"
                        style="
                          width: 120px;
                          height: 70px;
                          object-fit: cover;
                          border-radius: 10px;
                        "
                      />
                    </td>
                    <td v-html="truncateText(item.deskripsi, 50)"></td>
                    <td>{{ item.is_publish ? "Publish" : "No" }}</td>
                    <td>
                      <router-link
                        :to="{
                          name: 'Edit Artikel Event',
                          params: { id: item.id },
                        }"
                        class="btn btn-primary btn-rounded"
                      >
                        <i class="fa fa-pencil color-muted"></i>
                      </router-link>
                      <button
                        type="button"
                        class="btn btn-dark btn-rounded"
                        @click="deleteArtikel(item.id)"
                      >
                        <i class="fa fa-trash color-muted"></i>
                      </button>
                    </td>
                  </tr>
                </tbody>
              </table>
              <!-- Pagination -->
              <nav>
                <ul class="pagination justify-content-center">
                  <li
                    class="page-item"
                    :class="{ disabled: currentPage === 1 }"
                  >
                    <a
                      class="page-link"
                      href="javascript:void(0)"
                      @click="prevPage"
                      >Previous</a
                    >
                  </li>
                  <li
                    v-for="page in totalPages"
                    :key="page"
                    class="page-item"
                    :class="{ active: page === currentPage }"
                  >
                    <a
                      class="page-link"
                      href="javascript:void(0)"
                      @click="goToPage(page)"
                    >
                      {{ page }}
                    </a>
                  </li>
                  <li
                    class="page-item"
                    :class="{ disabled: currentPage === totalPages }"
                  >
                    <a
                      class="page-link"
                      href="javascript:void(0)"
                      @click="nextPage"
                      >Next</a
                    >
                  </li>
                </ul>
              </nav>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { computed, onMounted, ref } from "vue";
import { useRoute } from "vue-router";
import { useStore } from "vuex";
import Swal from "sweetalert2";
import axiosInstance from "../axios";

export default {
  name: "Artikel Event",

  setup() {
    const store = useStore();
    const router = useRoute();
    const currentRouteName = computed(() => router.name);
    const message = ref("You are not logged in!");
    const id_kategori = 3;
    const ArtEvent = ref([]);

    const currentPage = ref(1);
    const perPage = 10;
    const searchQuery = ref("");
    const totalItems = ref(0);

    const isAuthenticated = computed(() => store.getters.isAuthenticated);
    const token = computed(() => store.getters.token);
    const tokenExpiry = computed(() => store.getters.tokenExpiry);

    if (
      !token.value ||
      !tokenExpiry.value ||
      new Date() > new Date(tokenExpiry.value) ||
      !isAuthenticated.value
    ) {
      Swal.fire({
        title: "Error",
        text: `Session expired or invalid`,
        icon: "error",
        confirmButtonText: "OK",
      }).then(() => {
        store.dispatch("setAuth", false);
        store.dispatch("setToken", { token: null, expiry: null });
        localStorage.clear();
        window.location.href = "/";
      });
      return;
    }

    const fetchData = async () => {
      try {
        const getData = await axiosInstance.get(
          `/blog-kategori/${id_kategori}`,
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${token.value}`,
            },
          }
        );

        if (getData.status === 200) {
          const data = await getData.data;
          if (data.code === 200) {
            ArtEvent.value = data.data;
            totalItems.value = data.data.length;
          }
        } else {
          Swal.fire({
            title: "Error",
            text: `Error: ${data.message}`,
            icon: "error",
            confirmButtonText: "OK",
          });
        }
      } catch (error) {
        await Swal.fire({
          title: "Error",
          text: `Error: ${error.message}`,
          icon: "error",
          confirmButtonText: "OK",
        });
        // .then(() => {
        //     store.dispatch("setAuth", false);
        //     store.dispatch("setToken", { token: null, expiry: null });
        //     localStorage.clear();
        //     window.location.href = '/'
        // });
      }
    };

    const filteredData = computed(() => {
      return ArtEvent.value.filter((item) => {
        return item.title
          .toLowerCase()
          .includes(searchQuery.value.toLowerCase());
      });
    });

    const paginatedData = computed(() => {
      const start = (currentPage.value - 1) * perPage;
      const end = start + perPage;
      return filteredData.value.slice(start, end);
    });

    const totalPages = computed(() => {
      return Math.ceil(filteredData.value.length / perPage);
    });

    const handleSearch = () => {
      currentPage.value = 1; // Reset to first page on search
    };

    const goToPage = (page) => {
      if (page >= 1 && page <= totalPages.value) {
        currentPage.value = page;
      }
    };

    const nextPage = () => {
      if (currentPage.value < totalPages.value) {
        currentPage.value++;
      }
    };

    const prevPage = () => {
      if (currentPage.value > 1) {
        currentPage.value--;
      }
    };

    const truncateText = (text, length) => {
      return text.length > length ? text.slice(0, length) + "..." : text;
    };

    const deleteArtikel = async (id) => {
      try {
        const confirmation = await Swal.fire({
          title: "Are you sure?",
          text: "You won't be able to revert this!",
          icon: "warning",
          showCancelButton: true,
          confirmButtonText: "Yes, delete it!",
          cancelButtonText: "No, cancel!",
        });

        if (confirmation.isConfirmed) {
          const response = await axiosInstance.delete(`/blog/${id}`, {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${token.value}`,
            },
          });

          if (response.status === 200) {
            Swal.fire("Deleted!", "Your record has been deleted.", "success");
            ArtEvent.value = ArtEvent.value.filter((item) => item.id !== id);
          } else {
            Swal.fire(
              "Error!",
              "There was an issue deleting the record.",
              "error"
            );
          }
        }
      } catch (error) {
        Swal.fire("Error!", `Error: ${error.message}`, "error");
      }
    };

    onMounted(() => {
      fetchData();
    });

    return {
      currentRouteName,
      deleteArtikel,
      ArtEvent,
      fetchData,
      searchQuery,
      handleSearch,
      paginatedData,
      currentPage,
      totalPages,
      goToPage,
      perPage,
      nextPage,
      prevPage,
      truncateText,
    };
  },
};
</script>
