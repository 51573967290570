<template>
  <div class="content-body" style="padding-top: 6rem">
    <div class="container-fluid">
      <div class="page-titles">
        <ol class="breadcrumb">
          <li class="breadcrumb-item"><a href="javascript:void(0)">Info</a></li>
          <li class="breadcrumb-item">
            <router-link :to="{ name: 'Knowledge' }"
              >Data Knowledge</router-link
            >
          </li>
          <li class="breadcrumb-item">
            <a href="javascript:void(0)">{{ currentRouteName }}</a>
          </li>
        </ol>
      </div>

      <div class="row">
        <div class="col-12">
          <div class="card">
            <div class="card-header">
              <h4 class="card-title">Form {{ currentRouteName }}</h4>
            </div>
            <div class="card-body">
              <div class="basic-form">
                <div class="form-group row">
                  <label class="col-sm-3 col-form-label">Judul</label>
                  <div class="col-sm-9">
                    <input
                      v-model="editForm.title"
                      @input="generateSlug"
                      type="text"
                      class="form-control"
                      placeholder="Judul Post"
                    />
                  </div>
                </div>
                <div class="form-group row">
                  <label class="col-sm-3 col-form-label">Slug URL</label>
                  <div class="col-sm-9">
                    <input
                      v-model="editForm.slug"
                      type="text"
                      class="form-control"
                      placeholder="Slug URL"
                      readonly
                    />
                  </div>
                </div>

                <div class="form-group row">
                  <label class="col-sm-3 col-form-label">Icon</label>
                  <div class="col-sm-9">
                    <div v-if="editForm.imagePreviewIcon" class="mb-3">
                      <img
                        :src="editForm.imagePreviewIcon"
                        alt="icon"
                        class="img-thumbnail"
                        style="max-width: 200px; max-height: 200px"
                      />
                    </div>
                    <div>
                      <input
                        type="file"
                        class="d-none"
                        ref="iconFileInput"
                        accept="image/*"
                        @change="handleFileUploadIcon"
                      />
                      <button
                        type="button"
                        class="btn btn-primary btn-sm mr-1"
                        @click="triggerIconFilePicker"
                      >
                        Change
                      </button>
                    </div>
                  </div>
                </div>

                <div class="form-group row">
                  <label class="col-sm-3 col-form-label">Thumbnail</label>
                  <div class="col-sm-9">
                    <div v-if="editForm.imagePreview" class="mb-3">
                      <img
                        :src="editForm.imagePreview"
                        alt="Thumbnail"
                        class="img-thumbnail"
                        style="max-width: 200px; max-height: 200px"
                      />
                    </div>
                    <div>
                      <input
                        type="file"
                        class="d-none"
                        ref="thumbnailFileInput"
                        accept="image/*"
                        @change="handleFileUpload"
                      />
                      <button
                        type="button"
                        class="btn btn-primary btn-sm mr-1"
                        @click="triggerThumbnailFilePicker"
                      >
                        Change
                      </button>
                    </div>
                  </div>
                </div>

                <div class="form-group row">
                  <label class="col-sm-3 col-form-label">Multiple Image</label>
                  <div class="col-sm-9">
                    <div v-if="editForm.multiple.length">
                      <div class="d-flex flex-wrap">
                        <div
                          v-for="(file, index) in editForm.multiple"
                          :key="index"
                          class="position-relative mb-2"
                        >
                          <img
                            :src="file.url"
                            alt="Uploaded Image"
                            class="img-thumbnail"
                            style="max-width: 150px; max-height: 150px"
                          />
                          <button
                            type="button"
                            @click="removeFile(index)"
                            class="btn btn-danger btn-sm position-absolute"
                            style="top: 5px; right: 5px"
                          >
                            Remove
                          </button>
                        </div>
                      </div>
                    </div>
                    <div class="input-group">
                      <div class="custom-file">
                        <input
                          @change="handleAdditionalFiles"
                          type="file"
                          class="custom-file-input"
                          multiple
                        />
                        <label class="custom-file-label">
                          {{
                            editForm.multiple.length
                              ? `${editForm.multiple.length} files selected`
                              : "Choose files"
                          }}
                        </label>
                      </div>
                    </div>
                    <button @click="addMoreFiles" class="btn btn-primary mt-2">
                      Tambah Gambar
                    </button>
                    <input
                      ref="fileInput"
                      type="file"
                      class="d-none"
                      @change="handleAdditionalFiles"
                      multiple
                    />
                  </div>
                </div>

                <div class="form-group row">
                  <label class="col-sm-3 col-form-label">Image Caption</label>
                  <div class="col-sm-9">
                    <input
                      v-model="editForm.alt_image"
                      type="text"
                      class="form-control"
                      placeholder="Input Caption Image"
                    />
                  </div>
                </div>
                <div class="form-group row">
                  <label class="col-sm-3 col-form-label">Deskripsi</label>
                  <textarea
                    ref="summernote"
                    class="form-control summernote"
                    id="deskripsi"
                    v-model="editForm.deskripsi"
                    rows="3"
                  ></textarea>
                </div>
                <div class="form-group row">
                  <label class="col-sm-3 col-form-label">Keyword Tag</label>
                  <div class="col-sm-9">
                    <input
                      v-model="editForm.hastag"
                      type="text"
                      class="form-control"
                      placeholder="Input Keyword Tag"
                    />
                  </div>
                </div>

                <div class="card-header2 mt-5 mb-3">
                  <h4 class="card-title">Meta SEO</h4>
                </div>
                <div class="form-group row">
                  <label class="col-sm-3 col-form-label">Meta Title</label>
                  <div class="col-sm-9">
                    <input
                      v-model="editForm.meta_title"
                      type="text"
                      class="form-control"
                      placeholder="Input Meta Title"
                    />
                  </div>
                </div>

                <div class="form-group row">
                  <label class="col-sm-3 col-form-label"
                    >Meta Description</label
                  >
                  <div class="col-sm-9">
                    <input
                      v-model="editForm.meta_deskripsi"
                      type="text"
                      class="form-control"
                      placeholder="Max. 160 Characters"
                    />
                  </div>
                </div>

                <div class="form-group row">
                  <label class="col-sm-3 col-form-label">Meta Content</label>
                  <div class="col-sm-9">
                    <input
                      v-model="editForm.meta_content"
                      type="text"
                      class="form-control"
                      placeholder="Input Meta Content"
                    />
                  </div>
                </div>

                <div class="form-group row">
                  <label class="col-sm-3 col-form-label">Meta Name</label>
                  <div class="col-sm-9">
                    <input
                      v-model="editForm.meta_name"
                      type="text"
                      class="form-control"
                      placeholder="Input Meta Content"
                    />
                  </div>
                </div>
                <div class="form-group row">
                  <label class="col-sm-3 col-form-label">Status</label>
                  <div class="col-sm-9">
                    <select v-model="editForm.is_publish" class="form-control">
                      <option value="true">Publish</option>
                      <option value="false">Unpublish</option>
                    </select>
                  </div>
                </div>

                <!-- Spinner -->
                <div
                  v-if="isLoading"
                  class="d-flex justify-content-center mb-3"
                >
                  <div class="spinner-border" role="status">
                    <span class="sr-only">Loading...</span>
                  </div>
                </div>

                <!-- Tombol Simpan dan Kembali -->
                <button type="button" @click="goBack" class="btn btn-warning btn-sm mr-1">
                  Kembali
                </button>
                <button
                  type="button"
                  :disabled="isLoading"
                  @click="updateData"
                  class="btn btn-primary btn-sm mr-1"
                >
                  <!-- Ganti teks tombol jika sedang loading -->
                  <span v-if="isLoading">Menyimpan...</span>
                  <span v-else>Simpan</span>
                </button>
                <!-- <button type="button" @click="goBack" class="btn btn-warning">
                  Kembali
                </button>
                <button
                  type="button"
                  @click="updateData"
                  class="btn btn-primary"
                >
                  Simpan
                </button> -->
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { computed, ref, onMounted, nextTick } from "vue";
import { useRoute, useRouter } from "vue-router";
import { useStore } from "vuex";
import Swal from "sweetalert2";
import axiosInstance from "../../axios";

export default {
  name: "Edit Knowledge",
  setup() {
    const store = useStore();
    const route = useRoute();
    const router = useRouter();
    const summernote = ref(null);
    const fileInput = ref(null);
    const currentRouteName = computed(() => route.name);
    const thumbnailFileInput = ref(null);
    const iconFileInput = ref(null);
    const isLoading = ref(false);
    const editForm = ref({
      title: "",
      slug: "",
      image: null,
      icon: null,
      imagePreviewIcon: null,
      imagePreview: null,
      alt_image: "",
      deskripsi: "",
      hastag: "",
      meta_title: "",
      meta_deskripsi: "",
      meta_content: "",
      meta_name: "",
      is_publish: "true",
      user: null,
      id_kategori: 17,
      multiple: [],
      existingImages: [],
      existingIcon: [],
    });
    const kategoriList = ref([]);
    const token = computed(() => store.state.token);
    const id_kategori = computed(() => editForm.value.id_kategori);

    const fetchUser = async () => {
      try {
        const response = await axiosInstance.get("/user", {
          headers: {
            Authorization: `Bearer ${token.value}`,
          },
        });
        if (response.status === 200) {
          editForm.value.user = response.data.id;
        } else {
          throw new Error("Failed to fetch user data");
        }
      } catch (error) {
        handleAuthError(error);
      }
    };

    const fetchData = async () => {
      const id = route.params.id;
      try {
        const response = await axiosInstance.get(`/blog/${id}`, {
          headers: {
            Authorization: `Bearer ${token.value}`,
          },
        });

        if (response.status === 200) {
          const data = response.data.data;
          const imageUrl = `https://api.dev.bintangsempurna.co.id/images/blogs/${data.image}`;
          const iconUrl = `https://api.dev.bintangsempurna.co.id/images/blogs/icon/${data.icon}`;
          // const knowledgeArray = Array.isArray(data.Knowledge)
          //   ? data.Knowledge
          //   : [];
          // console.log("Knowledge Data:", knowledgeArray); // Debugging
          editForm.value = {
            ...editForm.value,
            title: data.title,
            slug: data.slug,
            alt_image: data.alt_image,
            deskripsi: data.deskripsi,
            hastag: data.hastag,
            meta_title: data.meta_title,
            meta_deskripsi: data.meta_deskripsi,
            meta_content: data.meta_content,
            meta_name: data.meta_name,
            is_publish: data.is_publish || "true",
            user: editForm.value.user || null,
            id_kategori: data.id_kategori || 17,
            imagePreview: data.image ? imageUrl : null,
            imagePreviewIcon: data.icon ? iconUrl : null,
            image: data.image ? data.image : null,
            icon: data.icon ? data.icon : null,
            existingImages: (data.existing_images || []).map((img) => ({
              id: img.id,
              url: `https://api.dev.bintangsempurna.co.id/images/blogs/knowledge/${img.image}`,
            })),
            existingIcon: (data.existing_icon || []).map((img) => ({
              id: img.id,
              url: `https://api.dev.bintangsempurna.co.id/images/blogs/icon/${img.icon}`,
            })),
            multiple:
              data.knowledge.map((img) => ({
                ...img,
                url: `https://api.dev.bintangsempurna.co.id/images/blogs/knowledge/${img.image}`,
              })) || [],
          };
          console.log("Multiple Images After Fetch:", editForm.value.multiple);
          $(summernote.value).summernote("code", editForm.value.deskripsi);
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };
    const deletedImages = ref([]);
    const deletedIcon = ref([]);

    const updateData = async () => {
      isLoading.value = true;
      editForm.value.deskripsi = $(summernote.value).summernote("code");
      const formData = new FormData();

      Object.keys(editForm.value).forEach((key) => {
        if (
          key !== "image" &&
          key !== "imagePreview" &&
          editForm.value[key] !== ""
        ) {
          formData.append(key, editForm.value[key]);
        }
      });

      if (editForm.value.image instanceof File) {
        formData.append("image", editForm.value.image);
      } else if (typeof editForm.value.image === "string") {
        formData.append("image", editForm.value.image);
      }

      editForm.value.multiple.forEach((fileObj, index) => {
        if (fileObj.file instanceof File) {
          formData.append(`new_images[]`, fileObj.file);
        } else {
          formData.append(`existing_images[]`, fileObj.image);
        }
      });

      formData.append("deleted_images", JSON.stringify(deletedImages.value));

      Object.keys(editForm.value).forEach((key) => {
        if (
          key !== "icon" &&
          key !== "imagePreviewIcon" &&
          editForm.value[key] !== ""
        ) {
          formData.append(key, editForm.value[key]);
        }
      });

      if (editForm.value.icon instanceof File) {
        formData.append("icon", editForm.value.icon);
      } else if (typeof editForm.value.icon === "string") {
        formData.append("icon", editForm.value.icon);
      }

      editForm.value.multiple.forEach((fileObj, index) => {
        if (fileObj.file instanceof File) {
          formData.append(`new_icon[]`, fileObj.file);
        } else {
          formData.append(`existing_icon[]`, fileObj.icon);
        }
      });

      formData.append("deleted_icon", JSON.stringify(deletedIcon.value));

      try {
        console.log("Data yang dikirim ke API:", ...formData.entries());
        for (let [key, value] of formData.entries()) {
          console.log(`${key}:`, value);
        }

        const response = await axiosInstance.post(
          `/blog-update/${route.params.id}`,
          formData,
          {
            headers: {
              Authorization: `Bearer ${token.value}`,
              "Content-Type": "multipart/form-data",
            },
          }
        );
        if (response.status === 200) {
          if (response.data.success === false) {
            Swal.fire("Error", `Error: ${response.data.message}`, "error");
          } else {
            console.log(response.data);
            Swal.fire("Success", "Data updated successfully", "success");
            router.push({ name: "Knowledge" });
          }
        }
      } catch (error) {
        Swal.fire("Error", `Error: ${error.message}`, "error");
      } finally {
        isLoading.value = false; // Selesai loading
      }
    };

    const handleFileUpload = (event) => {
      const file = event.target.files[0];
      if (file) {
        editForm.value.image = file;
        editForm.value.imagePreview = URL.createObjectURL(file);
      }
    };

    const handleFileUploadIcon = (event) => {
      const file = event.target.files[0];
      if (file) {
        editForm.value.icon = file;
        editForm.value.imagePreviewIcon = URL.createObjectURL(file);
      }
    };

    const generateSlug = () => {
      const title = editForm.value.title;
      const slug = title
        .toLowerCase()
        .replace(/ /g, "-")
        .replace(/[^\w-]+/g, "");
      editForm.value.slug = slug;
    };
    const addMoreFiles = () => {
      fileInput.value.click();
    };
    const resetFileInput = () => {
      fileInput.value.value = null;
    };
    const handleAdditionalFiles = (event) => {
      const files = Array.from(event.target.files);
      files.forEach((file) => {
        const fileUrl = URL.createObjectURL(file);
        editForm.value.multiple.push({
          id: Date.now(), // Unique ID for new file
          url: fileUrl,
          file: file,
        });
      });
      resetFileInput();
    };

    const triggerThumbnailFilePicker = () => {
      thumbnailFileInput.value.click();
    };

    // Function to trigger the file picker for the icon
    const triggerIconFilePicker = () => {
      iconFileInput.value.click();
    };

    const removeFile = (index) => {
      const removedFile = editForm.value.multiple.splice(index, 1);
      if (!(removedFile.file instanceof File)) {
        deletedImages.value.push(removedFile.id);
      }
    };

    const removeExistingImage = (index) => {
      editForm.value.existingImages.splice(index, 1);
    };

    const removeExistingIcon = (index) => {
      editForm.value.existingIcon.splice(index, 1);
    };

    const goBack = () => {
      router.push({ name: "Knowledge" });
    };

    onMounted(async () => {
      await fetchUser();
      await fetchData();
      await nextTick(() => {
        $(summernote.value).summernote({
          height: 200,
          callbacks: {
            onChange: (contents) => {
              editForm.value.deskripsi = contents;
            },
          },
        });
      });
    });

    return {
      editForm,
      summernote,
      fileInput,
      currentRouteName,
      addMoreFiles,
      removeFile,
      removeExistingImage,
      removeExistingIcon,
      handleAdditionalFiles,
      fetchData,
      updateData,
      handleFileUpload,
      handleFileUploadIcon,
      generateSlug,
      goBack,
      thumbnailFileInput,
      iconFileInput,
      triggerThumbnailFilePicker,
      triggerIconFilePicker,
      isLoading,
    };
  },
};
</script>
