<template>
  <div class="auth-wrapper">
    <div class="auth-content">
      <p class="text-center">
        <router-link class="auth-content-logo-header" to="/">
          <img :src="logo" alt="logo" />
        </router-link>
      </p>
      <div class="card">
        <div class="card-body text-left">
          <h3 class="mb-4 auth-login-title text-left">Masuk Akun
          </h3>
          <span>👋 Welcome back, Admin! Yuk, masuk dan buat Bintang Sempurna makin gemilang!</span>
          <div v-if="emailError" class="error-message">{{ emailError }}</div>
          <div class="input-group mb-3 mt-3">
            <input
              type="email"
              class="form-control"
              v-model="user.email"
              placeholder="Email"
              @input="validateEmail"
              required
            />
          </div>
          <div class="input-group mb-4">
            <input
              :type="passwordFieldType"
              class="form-control"
              v-model="user.password"
              placeholder="Password"
              required
            />
            <button
              type="button"
              class="btn toggle-password"
              @click="togglePasswordVisibility"
            >
              <i
                :class="
                  passwordFieldType === 'password'
                    ? 'fas fa-eye'
                    : 'fas fa-eye-slash'
                "
              ></i>
            </button>
          </div>
          <button
            class="btn btn-login-auth shadow-2 mb-4 text-white"
            type="button"
            @click.prevent="submit"
          >
            Login
          </button>
        </div>
      </div>
     <!-- footer -->
     <p class="font-weight-normal text-center text-white">
        © <span id="currentYear"></span> PT. Bintang Sempurna. All Rights
        Reserved.
      </p>
    </div>
  </div>
</template>



<script>
import { reactive, ref } from "vue";
import { useRouter } from "vue-router";
import { useStore } from "vuex";
import Swal from "sweetalert2";
import axiosInstance from "../axios";

const logo = "/assets/images/img-bs/logo/logo-white.png"; // Logo path



export default {
  setup() {
    const user = reactive({
      email: "",
      password: "",
      recaptchaToken: "", // Tambahkan token reCAPTCHA
    });

    const emailError = ref("");

    const router = useRouter();
    const store = useStore();
    const passwordFieldType = ref("password");

    const validateEmail = () => {
      const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      if (!emailPattern.test(user.email)) {
        emailError.value = "Invalid email address.";
      } else {
        emailError.value = "";
      }
    };

    const togglePasswordVisibility = () => {
      passwordFieldType.value =
        passwordFieldType.value === "password" ? "text" : "password";
    };

    // const submit = async () => {
    //   if (!user.email || !user.password) {
    //     Swal.fire({
    //       icon: "error",
    //       title: "Input Required",
    //       text: "Please fill in both email and password fields",
    //       confirmButtonText: "OK",
    //     });
    //     return;
    //   }
    //   validateEmail();
    //   if (emailError.value) {
    //     return;
    //   }

    //   console.log("User Data:", user);

    //   try {
    //     grecaptcha.ready(function () {
    //       grecaptcha
    //         .execute("6LcexFkqAAAAAP_nGLtk-CT5GJWnUZO7vr_Z597u", {
    //           action: "submit",
    //         })
    //         .then(async function (token) {
    //           user.recaptchaToken = token;

    //           if (!user.recaptchaToken) {
    //             Swal.fire({
    //               icon: "error",
    //               title: "Login Failed",
    //               text: "Please complete the reCAPTCHA verification",
    //               confirmButtonText: "OK",
    //             });
    //             return;
    //           }

    //           const response = await axiosInstance.post("/login", user);
    //           console.log("Response Data:", response);
    //           if (response.status === 200) {
    //             const responseData = response.data;

    //             if (responseData.code === 200) {
    //               const { token, tokenExpiry, v } = responseData.data;
    //               store.dispatch("setAuth", true);
    //               store.dispatch("setToken", {
    //                 token,
    //                 expiry: tokenExpiry,
    //                 v: v,
    //               });
    //               window.location.href = "/dashboard";
    //             } else {
    //               Swal.fire({
    //                 icon: "error",
    //                 title: "Login Failed",
    //                 text: responseData.message,
    //                 confirmButtonText: "OK",
    //               });
    //             }
    //           } else {
    //             Swal.fire({
    //               icon: "error",
    //               title: "Login Failed",
    //               text: `Login failed: Username / Password Salah`,
    //               confirmButtonText: "OK",
    //             });
    //           }
    //         });
    //     });
    //   } catch (error) {
    //     if (error.response && error.response.status === 404) {
    //       Swal.fire({
    //         icon: "error",
    //         title: "404 Not Found",
    //         text: "The requested resource was not found. Please check the URL or try again later.",
    //         confirmButtonText: "OK",
    //       });
    //     } else if (error.response && error.response.status === 401) {
    //       Swal.fire({
    //         icon: "error",
    //         title: "Unauthorized",
    //         text: "Incorrect username or password",
    //         confirmButtonText: "OK",
    //       });
    //     } else {
    //       Swal.fire({
    //         icon: "error",
    //         title: "Login Failed",
    //         text: `Error: ${error.response?.data?.message || error.message}`,
    //         confirmButtonText: "OK",
    //       });
    //     }
    //   }
    // };

    const submit = async () => {
      if (!user.email || !user.password) {
        Swal.fire({
          icon: "error",
          title: "Input Required",
          text: "Please fill in both email and password fields",
          confirmButtonText: "OK",
        });
        return;
      }

      validateEmail();
      if (emailError.value) {
        return;
      }

      console.log("User Data:", user);

      try {
        grecaptcha.ready(function () {
          grecaptcha
            .execute("6LcexFkqAAAAAP_nGLtk-CT5GJWnUZO7vr_Z597u", {
              action: "submit",
            })
            .then(async function (token) {
              user.recaptchaToken = token;

              // Cek apakah token reCAPTCHA ada
              if (!user.recaptchaToken) {
                Swal.fire({
                  icon: "error",
                  title: "Login Failed",
                  text: "Please complete the reCAPTCHA verification",
                  confirmButtonText: "OK",
                });
                return;
              }

              // Lakukan permintaan login
              try {
                const response = await axiosInstance.post("/login", user);

                console.log("Response Data:", response);

                const responseData = response.data;

                // Gunakan responseData.code untuk memeriksa hasil
                if (responseData.code === 200) {
                  const { token, tokenExpiry, v } = responseData.data;
                  store.dispatch("setAuth", true);
                  store.dispatch("setToken", {
                    token,
                    expiry: tokenExpiry,
                    v: v,
                  });
                  window.location.href = "/dashboard";
                } else {
                  Swal.fire({
                    icon: "error",
                    title: "Login Failed",
                    text:
                      responseData.message ||
                      "Login failed: Invalid credentials",
                    confirmButtonText: "OK",
                  });
                }
              } catch (error) {
                // Tangani error lain selain dari code response
                if (error.response && error.response.status === 404) {
                  Swal.fire({
                    icon: "error",
                    title: "Wrong",
                    text: "Incorrect email or password",
                    confirmButtonText: "OK",
                  });
                } else if (error.response && error.response.status === 401) {
                  Swal.fire({
                    icon: "error",
                    title: "Unauthorized",
                    text: "Incorrect email or password",
                    confirmButtonText: "OK",
                  });
                } else {
                  Swal.fire({
                    icon: "error",
                    title: "Login Failed",
                    text: `Error: ${
                      error.response?.data?.message || error.message
                    }`,
                    confirmButtonText: "OK",
                  });
                }
              }
            });
        });
      } catch (error) {
        console.error("Error during reCAPTCHA execution:", error);
        Swal.fire({
          icon: "error",
          title: "reCAPTCHA Error",
          text: "An error occurred while executing reCAPTCHA. Please try again.",
          confirmButtonText: "OK",
        });
      }
    };

    return {
      user,
      emailError,
      validateEmail,
      passwordFieldType,
      togglePasswordVisibility,
      submit,
      logo, // Include logo untuk digunakan di template
    };
  },

  mounted() {
    const currentYear = new Date().getFullYear();
    document.getElementById("currentYear").textContent = currentYear;
  },
};

</script>

<style scoped>

.input-group>.form-control:not(:last-child), .input-group>.custom-select:not(:last-child) {
    border-top-right-radius: 20px;
    border-bottom-right-radius: 20px;
}
.auth-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 100vh;
  background: linear-gradient(129.86deg, #e20613 45.92%, #ac000a 82.26%);
}

.auth-content {
  width: 450px;
  padding: 15px;
}

.auth-content-logo-header img {
  width: 50%;
  margin: 0 auto;
}

.card {
  border: none;
  background: #f8fafe;
  box-shadow: 0px 4px 50px rgb(0 0 0 / 10%);
  border-radius: 15px;
  padding: 10px;
}

.auth-login-title {
  text-align: center;
  font-size: 26px;
  font-weight: 600;
  color: #232526;
}

.btn-login-auth {
  background: linear-gradient(90deg, #e20613 0%, #f46308 61.98%);
  font-weight: bold;
  font-size: 15px;
  padding: 7px 30px;
  color: #fff;
  display: block;
  width: 100%;
  border-radius: 28px;
  margin-top: 35px;
}

.btn-login-auth:hover {
  background-color: #ac0d0d;
  border-color: #ac0d0d;
}

.error-message {
  color: red;
  font-size: 0.875em;
}

.toggle-password {
  position: absolute;
  right: 10px;
  top: 50%;
  transform: translateY(-50%);
  background: transparent;
  border: none;
  font-size: 0.875em;
  color: #000000;
  cursor: pointer;
}
</style>
