<template>
  <div>
    <component :is="layout">
      <router-view />
    </component>
  </div>
</template>

<script>
import AuthLayout from "@/layouts/AuthLayout.vue";
import MainLayout from "@/layouts/MainLayout.vue";

export default {
  components: {
    AuthLayout,
    MainLayout,
  },
  computed: {
    layout() {
      return this.$route.meta.requiresAuth ? "MainLayout" : "AuthLayout";
    },
  },
};
</script>

<style scoped></style>
