<template>
  <div class="content-body" style="padding-top: 6rem">
    <div class="container-fluid">
      <div class="page-titles">
        <ol class="breadcrumb">
          <li class="breadcrumb-item">
            <router-link :to="{ name: 'Section Head' }"
              >Data Section Head Page</router-link
            >
          </li>
          <li class="breadcrumb-item">
            <a href="javascript:void(0)">{{ currentRouteName }}</a>
          </li>
        </ol>
      </div>

      <div class="row">
        <div class="col-12">
          <div class="card">
            <div class="card-header">
              <h4 class="card-title">Form {{ currentRouteName }}</h4>
            </div>
            <div class="card-body">
              <div class="basic-form">
                <div class="form-group row">
                  <label class="col-sm-3 col-form-label">Judul</label>
                  <div class="col-sm-9">
                    <input
                      v-model="editForm.title"
                      type="text"
                      class="form-control"
                      placeholder="Judul Post"
                    />
                  </div>
                </div>
                <div class="form-group row">
                  <label class="col-sm-3 col-form-label">Kategori</label>
                  <div class="col-sm-9">
                    <select v-model="editForm.id_kategori" class="form-control">
                      <option
                        v-for="kategori in kategoriList"
                        :key="kategori.id"
                        :value="kategori.id"
                      >
                        {{ kategori.judul }}
                      </option>
                    </select>
                  </div>
                </div>
                <div class="form-group row">
                  <label class="col-sm-3 col-form-label">Image</label>
                  <div class="col-sm-9">
                    <div v-if="editForm.imagePreview" class="mb-3">
                      <img
                        :src="editForm.imagePreview"
                        alt="Thumbnail"
                        class="img-thumbnail"
                        style="max-width: 200px; max-height: 200px"
                      />
                    </div>
                    <div>
                      <input
                        type="file"
                        class="d-none"
                        ref="fileInput"
                        accept="image/*"
                        @change="handleFileUpload"
                      />
                      <button
                        type="button"
                        class="btn btn-primary btn-sm mr-1"
                        @click="triggerFilePicker"
                      >
                        Change
                      </button>
                    </div>
                  </div>
                </div>
                <div class="form-group row">
                  <label class="col-sm-3 col-form-label">Image Caption</label>
                  <div class="col-sm-9">
                    <input
                      v-model="editForm.alt_image"
                      type="text"
                      class="form-control"
                      placeholder="Input Caption Image"
                    />
                  </div>
                </div>
                <div class="form-group row">
                  <label class="col-sm-3 col-form-label">Deskripsi</label>
                  <textarea
                    ref="summernote"
                    class="form-control summernote"
                    id="deskripsi"
                    v-model="editForm.deskripsi"
                    rows="3"
                  ></textarea>
                </div>
                <div class="form-group row">
                  <label class="col-sm-3 col-form-label">Status</label>
                  <div class="col-sm-9">
                    <select v-model="editForm.is_publish" class="form-control">
                      <option value="true">Publish</option>
                      <option value="false">Unpublish</option>
                    </select>
                  </div>
                </div>
                <button type="button" @click="goBack" class="btn btn-warning btn-sm mr-1">
                  Kembali
                </button>
                <button
                  type="button"
                  @click="updateData"
                  class="btn btn-primary btn-sm mr-1"
                >
                  Simpan
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { computed, ref, onMounted, nextTick, watch } from "vue";
import { useRoute, useRouter } from "vue-router";
import { useStore } from "vuex";
import Swal from "sweetalert2";
import axiosInstance from "../../axios";

export default {
  name: "Edit Section Head",
  setup() {
    const store = useStore();
    const route = useRoute();
    const router = useRouter();
    const summernote = ref(null);
    const fileInput = ref(null);
    const currentRouteName = computed(() => route.name);
    const editForm = ref({
      title: "",
      slug: "",
      image: null,
      imagePreview: null,
      alt_image: "",
      deskripsi: "",
      hastag: "",
      meta_title: "",
      meta_deskripsi: "",
      meta_content: "",
      meta_name: "",
      is_publish: "true",
      user: null,
      id_kategori: "",
    });
    const kategoriList = ref([]);
    const token = computed(() => store.state.token);

    const fetchUser = async () => {
      try {
        const response = await axiosInstance.get("/user", {
          headers: {
            Authorization: `Bearer ${token.value}`,
          },
        });
        if (response.status === 200) {
          editForm.value.user = response.data.id;
        } else {
          throw new Error("Failed to fetch user data");
        }
      } catch (error) {
        handleAuthError(error);
      }
    };

    const fetchData = async () => {
      const id = route.params.id;
      try {
        const response = await axiosInstance.get(`/header/${id}`, {
          headers: {
            Authorization: `Bearer ${token.value}`,
          },
        });
        if (response.status === 200) {
          const imageUrl = `https://api.dev.bintangsempurna.co.id/images/blogs/header/${response.data.data.image}`;
          editForm.value = {
            ...editForm.value,
            title: response.data.data.title,
            alt_image: response.data.data.alt_image,
            deskripsi: response.data.data.deskripsi,
            is_publish: response.data.data.is_publish || "true",
            user: editForm.value.user || null,
            id_kategori: response.data.data.id_kategori,
            imagePreview: response.data.data.image ? imageUrl : null,
            image: response.data.data.image ? response.data.data.image : null,
          };
          $(summernote.value).summernote("code", editForm.value.deskripsi);
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    const kategori = async () => {
      try {
        const response = await axiosInstance.get(`/kategori`, {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token.value}`,
          },
        });
        if (response.status === 200) {
          kategoriList.value = response.data.data;
        } else {
          Swal.fire({
            title: "Error",
            text: error.message,
            icon: "error",
            confirmButtonText: "OK",
          });
        }
      } catch (error) {
        Swal.fire({
          title: "Error",
          text: error.message,
          icon: "error",
          confirmButtonText: "OK",
        }).then(() => {
          store.dispatch("setAuth", false);
          store.dispatch("setToken", { token: null, expiry: null });
          localStorage.clear();
          window.location.href = "/";
        });
      }
    };

    const updateData = async () => {
      editForm.value.deskripsi = $(summernote.value).summernote("code");
      const formData = new FormData();

      Object.keys(editForm.value).forEach((key) => {
        if (
          key !== "image" &&
          key !== "imagePreview" &&
          editForm.value[key] !== ""
        ) {
          formData.append(key, editForm.value[key]);
        }
      });

      if (editForm.value.image instanceof File) {
        formData.append("image", editForm.value.image);
      } else if (typeof editForm.value.image === "string") {
        formData.append("image", editForm.value.image);
      }

      try {
        //   console.log("Data yang dikirim ke API:", ...formData.entries());
        //   for (let [key, value] of formData.entries()) {
        //     console.log(`${key}:`, value);
        //   }

        const response = await axiosInstance.post(
          `/header-update/${route.params.id}`,
          formData,
          {
            headers: {
              Authorization: `Bearer ${token.value}`,
              "Content-Type": "multipart/form-data",
            },
          }
        );
        if (response.status === 200) {
          if (response.data.success === false) {
            Swal.fire("Error", `Error: ${response.data.message}`, "error");
          } else {
            console.log(response.data);
            Swal.fire("Success", "Data updated successfully", "success");
            router.push({ name: "Section Head" });
          }
        }
      } catch (error) {
        Swal.fire("Error", `Error: ${error.message}`, "error");
      }
    };

    const handleFileUpload = (event) => {
      const file = event.target.files[0];
      if (file) {
        editForm.value.image = file;
        editForm.value.imagePreview = URL.createObjectURL(file);
      }
    };

    const triggerFilePicker = () => {
      if (fileInput.value) {
        fileInput.value.click();
      }
    };

    const goBack = () => {
      router.push({ name: "Section Head" });
    };

    const handleAuthError = (error) => {
      Swal.fire({
        title: "Error",
        text: error.message,
        icon: "error",
        confirmButtonText: "OK",
      }).then(() => {
        store.dispatch("setAuth", false);
        store.dispatch("setToken", { token: null, expiry: null });
        localStorage.clear();
        router.push({ name: "Login" });
      });
    };

    onMounted(async () => {
      await fetchUser();
      await fetchData();
      await kategori();
      await nextTick(() => {
        $(summernote.value).summernote({
          height: 200,
          callbacks: {
            onChange: (contents) => {
              editForm.value.deskripsi = contents;
            },
          },
        });
      });
    });

    watch(
      () => editForm.value.id_kategori,
      async (newKategori, oldKategori) => {
        if (newKategori !== oldKategori) {
          console.log("id_kategori changed:", newKategori);
          await kategori();
        }
      }
    );

    return {
      currentRouteName,
      editForm,
      kategoriList,
      handleFileUpload,
      summernote,
      updateData,
      goBack,
      triggerFilePicker,
      fileInput,
    };
  },
};
</script>
