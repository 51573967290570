<template>
  <div class="footer">
    <div class="copyright">
      <p class="font-weight-normal">
        © <span id="currentYear"></span> PT. Bintang Sempurna. All Rights
        Reserved.
      </p>
    </div>
  </div>
</template>

<script>
export default {
  mounted() {
    const currentYear = new Date().getFullYear();
    document.getElementById("currentYear").textContent = currentYear;
  },
};
</script>
