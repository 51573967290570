<template>
  <div class="content-body" style="padding-top: 6rem">
    <div class="container-fluid">
      <div class="page-titles">
        <ol class="breadcrumb">
          <li class="breadcrumb-item"><a href="javascript:void(0)">Info</a></li>
          <li class="breadcrumb-item">
            <router-link :to="{ name: 'Bussines' }"
              >Data Retail/B2B</router-link
            >
          </li>
          <li class="breadcrumb-item">
            <a href="javascript:void(0)">{{ currentRouteName }}</a>
          </li>
        </ol>
      </div>

      <div class="row">
        <div class="col-12">
          <div class="card">
            <div class="card-header">
              <h4 class="card-title">Form {{ currentRouteName }}</h4>
            </div>
            <div class="card-body">
              <div class="basic-form">
                <div class="form-group row">
                  <label class="col-sm-3 col-form-label">Title</label>
                  <div class="col-sm-9">
                    <input
                      v-model="post.title"
                      type="text"
                      class="form-control"
                      placeholder="Title"
                    />
                  </div>
                </div>

                <div class="form-group row">
                  <label class="col-sm-3 col-form-label">Kategori</label>
                  <div class="col-sm-9">
                    <select v-model="post.jenis" class="form-control">
                      <option value="">Pilih Jenis</option>
                      <option value="1">Retail</option>
                      <option value="2">B2B</option>
                    </select>
                  </div>
                </div>
                <div class="form-group row">
                  <label class="col-sm-3 col-form-label">Foto</label>
                  <div class="col-sm-9">
                    <div v-if="post.imagePreview" class="mb-3">
                      <img
                        :src="post.imagePreview"
                        alt="Thumbnail"
                        class="img-thumbnail"
                        style="max-width: 200px; max-height: 200px"
                      />
                    </div>
                    <div>
                      <input
                        type="file"
                        class="d-none"
                        ref="fileInput"
                        accept="image/*"
                        @change="handleFileUpload"
                      />
                      <button
                        type="button"
                        class="btn btn-primary"
                        @click="triggerFilePicker"
                      >
                        Change
                      </button>
                    </div>
                  </div>
                </div>

                <div class="form-group row">
                  <label class="col-sm-3 col-form-label">Deskripsi</label>
                  <textarea
                    ref="summernote"
                    class="form-control summernote"
                    id="deskripsi"
                    v-model="post.deskripsi"
                    rows="3"
                  ></textarea>
                </div>

                <div class="form-group row">
                  <label class="col-sm-3 col-form-label">Link URL</label>
                  <div class="col-sm-9">
                    <input
                      v-model="post.url_wa"
                      type="url"
                      class="form-control"
                      placeholder="Enter a valid link URL"
                    />
                  </div>
                </div>

                <div class="form-group row">
                  <label class="col-sm-3 col-form-label">Status</label>
                  <div class="col-sm-9">
                    <select v-model="post.is_publish" class="form-control ">
                      <option value="true">Publish</option>
                      <option value="false">Unpublish</option>
                    </select>
                  </div>
                </div>
                <button type="button" @click="goBack" class="btn btn-warning btn-sm mr-1">
                  Kembali
                </button>
                <button
                  type="button"
                  @click="updateData"
                  class="btn btn-primary btn-sm mr-1"
                >
                  Update
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { computed, onMounted, ref, nextTick, watch } from "vue";
import { useRoute, useRouter } from "vue-router";
import { useStore } from "vuex";
import Swal from "sweetalert2";
import axiosInstance from "../../axios";

export default {
  name: "Edit Product",
  setup() {
    const store = useStore();
    const route = useRoute();
    const router = useRouter();
    const summernote = ref(null);
    const fileInput = ref(null);
    const currentRouteName = computed(() => route.name);

    const post = ref({
      title: "",
      jenis: "",
      image: null,
      deskripsi: "",
      url_wa: "",
      is_publish: "",
      user: null,
    });

    const isAuthenticated = computed(() => store.getters.isAuthenticated);
    const token = computed(() => store.getters.token);
    const tokenExpiry = computed(() => store.getters.tokenExpiry);

    if (
      !token.value ||
      !tokenExpiry.value ||
      new Date() > new Date(tokenExpiry.value) ||
      !isAuthenticated.value
    ) {
      Swal.fire({
        title: "Error",
        text: `Session expired or invalid`,
        icon: "error",
        confirmButtonText: "OK",
      }).then(() => {
        store.dispatch("setAuth", false);
        store.dispatch("setToken", { token: null, expiry: null });
        localStorage.clear();
        router.replace({ name: "Login" });
      });
      return;
    }

    const fetchUser = async () => {
      try {
        const response = await axiosInstance.get("/user", {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token.value}`,
          },
        });
        if (response.status === 200) {
          post.value.user = response.data.id;
        } else {
          throw new Error("User not found");
        }
      } catch (error) {
        Swal.fire({
          title: "Error",
          text: error.message,
          icon: "error",
          confirmButtonText: "OK",
        }).then(() => {
          store.dispatch("setAuth", false);
          store.dispatch("setToken", { token: null, expiry: null });
          localStorage.clear();
          router.replace({ name: "Login" });
        });
      }
    };

    const fetchData = async () => {
      const id = route.params.id;
      try {
        const response = await axiosInstance.get(`/bussines/${id}`, {
          headers: {
            Authorization: `Bearer ${token.value}`,
          },
        });

        if (response.status === 200) {
          const imageUrl = `https://api.dev.bintangsempurna.co.id/images/bussines/${response.data.data.image}`;
          post.value = {
            ...post.value,
            title: response.data.data.title,
            jenis: response.data.data.jenis,
            deskripsi: response.data.data.deskripsi,
            url_wa: response.data.data.url_wa,
            is_publish: response.data.data.is_publish,
            user: post.value.user || null,
            imagePreview: response.data.data.image ? imageUrl : null,
            image: response.data.data.image ? response.data.data.image : null,
          };
          $(summernote.value).summernote("code", post.value.deskripsi);
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    const handleFileUpload = (event) => {
      const file = event.target.files[0];
      if (file) {
        post.value.image = file;
        post.value.imagePreview = URL.createObjectURL(file);
      }
    };

    const triggerFilePicker = () => {
      if (fileInput.value) {
        fileInput.value.click();
      }
    };

    const goBack = () => {
      router.push({ name: "Bussines" });
    };

    const updateData = async () => {
      post.value.deskripsi = $(summernote.value).summernote("code");
      const formData = new FormData();
      Object.keys(post.value).forEach((key) => {
        if (
          key !== "image" &&
          key !== "imagePreview" &&
          post.value[key] !== ""
        ) {
          formData.append(key, post.value[key]);
        }
      });

      if (post.value.image instanceof File) {
        formData.append("image", post.value.image);
      } else if (typeof post.value.image === "string") {
        formData.append("image", post.value.image);
      }

      try {
        const response = await axiosInstance.post(
          `/bussines-update/${route.params.id}`,
          formData,
          {
            headers: {
              "Content-Type": "multipart/form-data",
              Authorization: `Bearer ${token.value}`,
            },
          }
        );

        if (response.status === 200) {
          Swal.fire({
            title: "Success",
            text: "Data updated successfully!",
            icon: "success",
            confirmButtonText: "OK",
          }).then(() => {
            goBack();
          });
        } else {
          Swal.fire({
            title: "Error",
            text: "Error updating data",
            icon: "error",
            confirmButtonText: "OK",
          });
        }
      } catch (error) {
        Swal.fire({
          title: "Error",
          text: `Error: ${error.message}`,
          icon: "error",
          confirmButtonText: "OK",
        });
      }
    };

    onMounted(async () => {
      await fetchUser();
      await fetchData();
      await nextTick(() => {
        $(summernote.value).summernote({
          height: 200,
          callbacks: {
            onChange: (contents) => {
              post.value.deskripsi = contents;
            },
          },
        });
      });
    });

    // watch(
    //     post,
    //     () => post.value.id_kategori,
    //     async (newKategori, oldKategori) => {
    //     },
    //     { deep: true }
    // );

    return {
      post,
      fetchUser,
      currentRouteName,
      updateData,
      goBack,
      handleFileUpload,
      summernote,
      triggerFilePicker,
      fileInput,
    };
  },
};
</script>
