<template>
  <div class="content-body">
    <div class="container-fluid">
      <div class="page-titles">
        <ol class="breadcrumb">
          <li class="breadcrumb-item">
            <a href="javascript:void(0)">Logger Details</a>
          </li>
        </ol>
      </div>

      <div class="row">
        <div class="col-12">
          <div class="card">
            <div class="card-header">
              <h4 class="card-title">Log File: {{ logFileName }}</h4>
            </div>
            <div class="card-body">
              <div v-if="loading">
                <p>Loading logs...</p>
              </div>
              <div v-else>
                <div class="log-container">
                  <pre>{{ paginatedLogs }}</pre>
                </div>
              </div>

              <div class="pagination mt-3" v-if="!loading">
                <button
                  class="btn btn-secondary"
                  @click="loadMoreLogs"
                  v-if="canLoadMore"
                >
                  Load More Logs
                </button>
                <p v-else>No more logs to load.</p>
              </div>

              <div class="button-group mt-3">
                <button @click="downloadFile" class="btn btn-success">
                  <span v-if="loadingDownload">Downloading...</span>
                  <span v-else>Download File</span>
                </button>
                <button @click="goBack" class="btn btn-primary ml-2">
                  Back
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { onMounted, ref, computed } from "vue";
import { useRoute, useRouter } from "vue-router";
import { useStore } from "vuex";
import Swal from "sweetalert2";
import axiosInstance from "../axios";

export default {
  name: "Detail Logger",
  setup() {
    const route = useRoute();
    const router = useRouter();
    const store = useStore();
    const logContent = ref([]); // Store full log content
    const paginatedLogs = ref([]); // Store the currently displayed logs
    const currentPage = ref(0); // Track the current page for pagination
    const logsPerPage = ref(100); // Dynamically update based on file size
    const loading = ref(true); // Control loading state
    const canLoadMore = ref(false); // Determine if more logs can be loaded
    const logFileName = computed(() => route.params.id);
    const fileSize = ref(0); // Track the file size
    const loadingDownload = ref(false);

    const isAuthenticated = computed(() => store.getters.isAuthenticated);
    const token = computed(() => store.getters.token);
    const tokenExpiry = computed(() => store.getters.tokenExpiry);

    if (
      !token.value ||
      !tokenExpiry.value ||
      new Date() > new Date(tokenExpiry.value) ||
      !isAuthenticated.value
    ) {
      Swal.fire({
        title: "Error",
        text: `Session expired or invalid`,
        icon: "error",
        confirmButtonText: "OK",
      }).then(() => {
        store.dispatch("setAuth", false);
        store.dispatch("setToken", { token: null, expiry: null });
        localStorage.clear();
        window.location.href = "/";
      });
      return;
    }

    // Fetch logs (with caching)
    const fetchLogFile = async () => {
      loading.value = true;
      const cachedLogs = localStorage.getItem(logFileName.value);
      if (cachedLogs) {
        logContent.value = JSON.parse(cachedLogs); // Load from cache
        paginateLogs(); // Load the first set of logs
        loading.value = false;
        return;
      }

      try {
        const response = await axiosInstance.get(
          `/logger-view/${logFileName.value}`,
          {
            headers: {
              Authorization: `Bearer ${token.value}`,
            },
            responseType: "blob", // Fetch as blob to check size
          }
        );

        // Check file size (5 MB = 5242880 bytes)
        fileSize.value = response.data.size;
        if (fileSize.value > 5242880) {
          logsPerPage.value = 100; // If file size is over 5 MB, set per page logs to 100
        }

        const fileReader = new FileReader();
        fileReader.onload = (event) => {
          logContent.value = event.target.result.split("\n"); // Split by line
          localStorage.setItem(
            logFileName.value,
            JSON.stringify(logContent.value)
          ); // Cache logs
          paginateLogs(); // Load the first set of logs
        };
        fileReader.readAsText(response.data);
      } catch (error) {
        console.error("Request failed:", error); // Log any error
        Swal.fire({
          title: "Error",
          text: `Error: ${error.message}`,
          icon: "error",
          confirmButtonText: "OK",
        });
      } finally {
        loading.value = false;
      }
    };

    // Paginate logs
    const paginateLogs = () => {
      const start = currentPage.value * logsPerPage.value;
      const end = start + logsPerPage.value;
      paginatedLogs.value = logContent.value.slice(0, end).join("\n");
      currentPage.value += 100;
      canLoadMore.value = end < logContent.value.length;
    };

    const loadMoreLogs = () => {
      paginateLogs();
    };

    // Download log file
    const downloadFile = async () => {
      loadingDownload.value = true;
      try {
        const response = await axiosInstance.get(
          `/logger-view/${logFileName.value}/download-file`,
          {
            responseType: "blob", // Ensure it is downloaded as a file
            headers: {
              Authorization: `Bearer ${token.value}`,
            },
          }
        );

        if (response.status === 200) {
          const url = window.URL.createObjectURL(new Blob([response.data]));
          const link = document.createElement("a");
          link.href = url;
          link.setAttribute("download", logFileName.value); // Specify file name
          document.body.appendChild(link);
          link.click();
        } else {
          Swal.fire({
            title: "Error",
            text: `Error: ${response.data.message}`,
            icon: "error",
            confirmButtonText: "OK",
          });
        }
      } catch (error) {
        console.error("Download failed:", error);
        Swal.fire({
          title: "Error",
          text: `Error: ${error.message}`,
          icon: "error",
          confirmButtonText: "OK",
        });
      } finally {
        loadingDownload.value = false;
      }
    };

    const goBack = () => {
      router.push({ name: "Logger" });
    };

    // Fetch logs on component mount
    onMounted(() => {
      fetchLogFile();
    });

    return {
      logFileName,
      paginatedLogs,
      loading,
      canLoadMore,
      loadMoreLogs,
      downloadFile,
      loadingDownload,
      goBack,
    };
  },
};
</script>
<style scoped>
.content-body {
  padding: 30px;
  background: #f4f4f9;
}

.log-container {
  max-height: 500px;
  overflow-y: auto;
  overflow-x: auto; /* Enable horizontal scroll */
  border: 1px solid #ccc;
  padding: 10px;
  border-radius: 5px;
  background-color: #f8f9fa;
}

pre {
  white-space: pre-wrap; /* Preserve line breaks and spaces */
  word-wrap: break-word;
  white-space: pre; /* Prevent wrapping */
  margin: 0;
  font-family: monospace;
}

.pagination {
  display: flex;
  justify-content: center;
  margin-top: 15px;
}

.button-group {
  display: flex;
  justify-content: start;
  gap: 10px;
}
</style>
