<template>
  <div class="content-body" style="padding-top: 6rem">
    <div class="container-fluid">
      <!-- <div class="page-titles">
        <ol class="breadcrumb">
          <li class="breadcrumb-item">
            <a href="javascript:void(0)">{{ currentRouteName }}</a>
          </li>
        </ol>
      </div> -->

      <!-- Button Add -->
      <button
        type="button"
        class="btn btn-primary mb-2"
        data-toggle="modal"
        data-target="#JobsModal"
        @click="openModal(false)"
      >
        Tambah
      </button>
      <!-- End Button Add -->

      <div class="row">
        <div class="col-12">
          <div class="card">
            <div class="card-header">
              <div class="form-group">
                <h4 class="card-title mb-3">Cari Data  {{ currentRouteName }}</h4>
                <!-- Search bar -->
                <div class="d-flex align-items-center">
                  <input
                    v-model="searchQuery"
                    @input="handleSearch"
                    class="form-control mr-2"
                    placeholder="Cari berdasarkan nama..."
                    style="width: 250px"
                  />
                  <button @click="handleSearch" class="btn btn-primary btn-sm">
                    <i class="fa fa-search"></i>
                  </button>
                </div>
              </div>
            </div>
            <div class="card-body">
              <!-- <table class="table header-border table-responsive-sm"> -->
                <div class="table-responsive">
                  <table class="table">
                <thead>
                  <tr>
                    <th>#</th>
                    <th>Division</th>
                    <th>Jobs</th>
                    <th>Action</th>
                  </tr>
                </thead>
                <tbody>
                  <tr
                    v-if="!filteredData || !filteredData.length"
                    class="text-center"
                  >
                    <td colspan="4">No Data</td>
                  </tr>
                  <tr v-for="(item, index) in paginatedData" :key="item.id">
                    <td>{{ index + 1 }}</td>
                    <td>{{ item.division.name_division }}</td>
                    <td>{{ item.name_type }}</td>
                    <td>
                      <button
                        @click="openModal(true, item)"
                        type="button"
                        class="btn btn-primary btn-sm mr-1 mb-1"
                        data-toggle="modal"
                        data-target="#JobsModal"
                      >
                        Edit
                      </button>
                      <button
                        @click="deleteData(item.id)"
                        type="button"
                        class="btn btn-dark btn-sm mr-1 mb-1"
                      >
                        Delete
                      </button>
                    </td>
                  </tr>
                </tbody>
              </table>
              </div>
              <!-- Pagination -->
              <nav>
                <ul class="pagination justify-content-end mt-3">
                  <li
                    class="page-item"
                    :class="{ disabled: currentPage === 1 }"
                  >
                    <a
                      class="page-link"
                      href="javascript:void(0)"
                      @click="prevPage"
                      >Previous</a
                    >
                  </li>
                  <li
                    v-for="page in totalPages"
                    :key="page"
                    class="page-item"
                    :class="{ active: page === currentPage }"
                  >
                    <a
                      class="page-link"
                      href="javascript:void(0)"
                      @click="goToPage(page)"
                    >
                      {{ page }}
                    </a>
                  </li>
                  <li
                    class="page-item"
                    :class="{ disabled: currentPage === totalPages }"
                  >
                    <a
                      class="page-link"
                      href="javascript:void(0)"
                      @click="nextPage"
                      >Next</a
                    >
                  </li>
                </ul>
              </nav>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <!-- Modal -->
  <ModalJobs
    :isEdit="isEdit"
    :editData="editData"
    @createData="createData"
    @updateData="updateData"
  />
</template>

<script>
import { onMounted, ref, computed } from "vue";
import { useRoute, useRouter } from "vue-router";
import { useStore } from "vuex";
import Swal from "sweetalert2";
import axiosInstance from "../axios";
import ModalJobs from "../components/modal/ModalJobs.vue";
export default {
  name: "Jobs",
  components: {
    ModalJobs,
  },
  setup() {
    const router = useRouter();
    const route = useRoute();
    const store = useStore();
    const currentRouteName = computed(() => router.name);
    const dataList = ref([]);
    const isEdit = ref(false);
    const editData = ref(null);

    const currentPage = ref(1);
    const itemsPerPage = 10;
    const searchQuery = ref("");
    const totalItems = ref(0);

    const isAuthenticated = computed(() => store.getters.isAuthenticated);
    const token = computed(() => store.getters.token);
    const tokenExpiry = computed(() => store.getters.tokenExpiry);

    const fetchData = async () => {
      if (
        !token.value ||
        !tokenExpiry.value ||
        new Date() > new Date(tokenExpiry.value) ||
        !isAuthenticated.value
      ) {
        Swal.fire({
          title: "Error",
          text: `Session expired or invalid`,
          icon: "error",
          confirmButtonText: "OK",
        }).then(() => {
          store.dispatch("setAuth", false);
          store.dispatch("setToken", { token: null, expiry: null });
          localStorage.clear();
          window.location.href = "/";
        });
        return;
      }

      try {
        const response = await axiosInstance.get("/jobs", {
          headers: {
            Authorization: `Bearer ${token.value}`,
          },
        });

        if (response.status === 200) {
          dataList.value = response.data.data;
        } else {
          Swal.fire({
            title: "Error",
            text: `Failed to fetch data`,
            icon: "error",
            confirmButtonText: "OK",
          });
        }
      } catch (error) {
        Swal.fire({
          title: "Error",
          text: error.message,
          icon: "error",
          confirmButtonText: "OK",
        });
      }
    };

    const openModal = (edit = false, data = null) => {
      isEdit.value = edit;
      editData.value = data;
    };

    const createData = async (formData) => {
      try {
        const response = await axiosInstance.post("/jobs", formData, {
          headers: {
            Authorization: `Bearer ${token.value}`,
          },
        });

        if (response.status === 200) {
          Swal.fire({
            title: "Success",
            text: "Data created successfully!",
            icon: "success",
            confirmButtonText: "OK",
          });
          await fetchData();
          $("#JobsModal").modal("hide");
        } else {
          Swal.fire({
            title: "Error",
            text: `Error: ${response.data.message}`,
            icon: "error",
            confirmButtonText: "OK",
          });
        }
      } catch (error) {
        Swal.fire({
          title: "Error",
          text: `Error: ${error.message}`,
          icon: "error",
          confirmButtonText: "OK",
        });
      }
    };

    const updateData = async (formData) => {
      try {
        const response = await axiosInstance.post(
          `/jobs-update/${editData.value.id}`,
          formData,
          {
            headers: {
              Authorization: `Bearer ${token.value}`,
            },
          }
        );

        if (response.status === 200) {
          Swal.fire({
            title: "Success",
            text: "Data updated successfully!",
            icon: "success",
            confirmButtonText: "OK",
          });
          await fetchData();
          $("#JobsModal").modal("hide");
        } else {
          Swal.fire({
            title: "Error",
            text: `Error: ${response.data.message}`,
            icon: "error",
            confirmButtonText: "OK",
          });
        }
      } catch (error) {
        Swal.fire({
          title: "Error",
          text: `Error: ${error.message}`,
          icon: "error",
          confirmButtonText: "OK",
        });
      }
    };

    const deleteData = async (id) => {
      const result = await Swal.fire({
        title: "Are you sure?",
        text: "You won't be able to revert this!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes, delete it!",
        cancelButtonText: "Cancel",
      });

      if (result.isConfirmed) {
        try {
          const response = await axiosInstance.delete(`/jobs/${id}`, {
            headers: {
              Authorization: `Bearer ${token.value}`,
            },
          });

          if (response.status === 200) {
            Swal.fire({
              title: "Deleted!",
              text: "Data deleted successfully!",
              icon: "success",
              confirmButtonText: "OK",
            });
            await fetchData();
            $("#JobsModal").modal("hide");
          } else {
            Swal.fire({
              title: "Error",
              text: `Error: ${response.data.message}`,
              icon: "error",
              confirmButtonText: "OK",
            });
          }
        } catch (error) {
          Swal.fire({
            title: "Error",
            text: `Error: ${error.message}`,
            icon: "error",
            confirmButtonText: "OK",
          });
        }
      }
    };

    const truncateText = (text, length) => {
      return text.length > length ? text.slice(0, length) + "..." : text;
    };

    const filteredData = computed(() => {
      return dataList.value.filter((item) => {
        const searchDivision = item.division.name_division
          .toLowerCase()
          .includes(searchQuery.value.toLowerCase());
        const searchJobType = item.name_type
          .toLowerCase()
          .includes(searchQuery.value.toLowerCase());

        return searchDivision || searchJobType;
      });
    });

    const paginatedData = computed(() => {
      const start = (currentPage.value - 1) * itemsPerPage;
      return filteredData.value.slice(start, start + itemsPerPage);
    });

    const totalPages = computed(() => {
      return Math.ceil(filteredData.value.length / itemsPerPage);
    });

    const handleSearch = () => {
      currentPage.value = 1; // Reset to first page on search
    };

    const goToPage = (page) => {
      if (page >= 1 && page <= totalPages.value) {
        currentPage.value = page;
      }
    };

    const nextPage = () => {
      if (currentPage.value < totalPages.value) {
        currentPage.value++;
      }
    };

    const prevPage = () => {
      if (currentPage.value > 1) {
        currentPage.value--;
      }
    };

    onMounted(fetchData);

    return {
      currentRouteName,
      dataList,
      isEdit,
      editData,
      openModal,
      createData,
      updateData,
      deleteData,
      searchQuery,
      handleSearch,
      filteredData,
      paginatedData,
      currentPage,
      totalPages,
      goToPage,
      itemsPerPage,
      nextPage,
      prevPage,
      truncateText,
    };
  },
};
</script>
